import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DateTime } from 'luxon';
import { contactProcessing } from '../shared/constants/contacts';
import { Contact, DataCache, PublishContact, RequestData } from '../shared/types/contacts';
import { IframePostMessageService } from './iframe-post-message.service';

const CACHE_TIMEOUT = 2;

@Injectable()
export class ContactsDatabaseCache {
  /**
   * Construtor
   * @param dbService injeta o provider para lidar com o indexDB do navegador
   * @param iframePostMessageService injeta o serviço para lidar com a comunicação com cliente
   */
  constructor(
    private dbService: NgxIndexedDBService,
    private iframePostMessageService: IframePostMessageService,
  ) {}

  /**
   * Deve inserir os contatos publicados em cache
   * @param contact lista de contatos
   */
  async publishContactsCache(publishContact: PublishContact): Promise<void> {
    const publishContactCache = publishContact.contacts.map((contact) => {
      return { id: `${this.iframePostMessageService.contactCacheUniqueKey}-${contact.contactId}`, create_at: new Date().getTime() };
    });

    this.dbService.bulkAdd('publish-contacts-cache', publishContactCache);

    return;
  }

  /**
   * Deve comparar os contatos com o cache para saber se o contato esta em processo de captura
   */
  public async contactMatchCache(requestData: RequestData): Promise<RequestData> {
    const contacts = requestData.data;

    const updatedContacts = contacts.map(async (contact) => {
      const contactCache = await firstValueFrom(this.dbService.getByKey<DataCache>('publish-contacts-cache', `${this.iframePostMessageService.contactCacheUniqueKey}-${contact.id}`));

      if (contactCache) {
        const diff = DateTime.now().diff(DateTime.fromMillis(contactCache.create_at), 'minutes');
        if (diff.minutes <= CACHE_TIMEOUT && !contact.disabled) return { ...contact, disabled: true, disabledReason: contactProcessing };
      }

      return contact as Contact;
    });

    return { ...requestData, data: await Promise.all(updatedContacts) };
  }

  /**
   * Remove os itens em cache que são mais antigos
   */
  public async removeOutdatedCache(): Promise<void> {
    const allCacheItems = await firstValueFrom(this.dbService.getAll<DataCache>('publish-contacts-cache'));

    for (const cacheItem of allCacheItems) {
      const diff = DateTime.now().diff(DateTime.fromMillis(cacheItem.create_at), 'minutes');
      if (diff.minutes > CACHE_TIMEOUT) await firstValueFrom(this.dbService.deleteByKey('publish-contacts-cache', cacheItem.id));
    }
  }

  /**
   * Deve remove o contato que está em cache (Processando)
   */
  public async removeContactFromCache(contactId: string): Promise<void> {
    await firstValueFrom(this.dbService.deleteByKey('publish-contacts-cache', `${this.iframePostMessageService.contactCacheUniqueKey}-${contactId}`));
  }
}
