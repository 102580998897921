/**
 * Remove os ltda e SA do nome da empresa para usar no search do LinkedIn
 * @param company nome da empresa
 * @return nome da empresa formatado para urlEncode
 */
export function formatCompanyNameToLinkedIn(company: string): string {
  const regex = new RegExp(/(ltda|sa|s\/a|s\.a)?\.?$/, 'i');
  const result = company.trim().replace(regex, '');
  return encodeURIComponent(result.trimEnd());
}
