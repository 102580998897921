import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// Para Rodar monster-web-kit local

// import { defineCustomElements } from '@techramper/monster-web-kit-core/loader/index';
// defineCustomElements();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
