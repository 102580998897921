import { EmailReliabilityEnum } from 'src/app/shared/enums/contacts';
import { PropsReliability } from 'src/app/shared/types/components';

/**
 * Deve retornar o propriedades referente a cada confiabilidade
 * @param reliability enum da confiabilidade
 * @returns um objeto com as propriedades referente a confiabilidade
 */
export function getPropsReliability(reliability: EmailReliabilityEnum): PropsReliability {
  switch (reliability) {
    case EmailReliabilityEnum.HIGH_RELIABILITY:
      return { text: 'Alta', color: 'green' };

    case EmailReliabilityEnum.MEDIUM_RELIABILITY:
      return { text: 'Média', color: 'orange' };

    case EmailReliabilityEnum.LOW_RELIABILITY:
      return { text: 'Baixa', color: 'red' };

    case EmailReliabilityEnum.NO_RELIABILITY:
      return { text: 'Sem Confiabilidade', color: 'gray' };
  }
}
