import { TagStructure } from '@techramper/monster-web-kit-core/dist/types/components/ms-table/ms-table-interface';
import { PropsReliability } from 'src/app/shared/types/components';

/**
 * Deve criar uma estrutura que represente o componente tag
 */
export function createContactReliabilityComponent(reliability: PropsReliability): TagStructure | undefined {
  return {
    type: 'tag',
    content: reliability.text.toUpperCase(),
    color: reliability.color,
    prefix: { name: 'CircleFilled' },
    size: 'small',
  } as unknown as TagStructure;
}
