import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { getAccessToken, getRefreshToken, setCookie } from '../utils/cookie/cookie-auth';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class JwtInjectInterceptorService implements HttpInterceptor {
  /**
   * Construtor
   * @param router injeta o provider para lidar navegação
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    this.startRefreshInterval();
  }

  /**
   * Cria uma rotina para atualizar o token / cookie a cada 58 minutos
   * o token e o cookie expira dentro de 1h, então temos 2 min de margem
   */
  private startRefreshInterval(): void {
    setInterval(() => this.refreshToken(), 58 * 60 * 1000);
  }

  /**
   * Deve consumir a api para realizar o refresh do token
   * Os novos tokens devem ser guardados em um cookie por 1h
   */
  private async refreshToken(): Promise<void> {
    const refreshToken = getRefreshToken();
    const response = await this.authService.refreshToken(refreshToken);

    setCookie(response);
  }

  /**
   * Deve lidar com exceções genéricas durante a comunicação com a api
   * @param error object contento as informações do erro
   */
  private handleServerExceptions(error: any): Promise<boolean> | undefined {
    if (error.status === 401 && error.url.includes('/v1/auth/refresh')) {
      return this.router.navigateByUrl('/status/session-expired');
    }

    if (error.status === 401) {
      return this.router.navigateByUrl('/status/unauthorized');
    }

    if (error.status === 500) {
      return this.router.navigateByUrl('/status/internal-server-error');
    }

    if (error.status === 404) {
      return this.router.navigateByUrl('/status/not-found');
    }

    return;
  }

  /**
   * Deve interceptar qualquer requisição e injetar o token
   * @param req dados requisição que foi interceptada
   * @param next evento que foi interceptado
   * @returns a continuidade da requisição
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = getAccessToken();
    const header = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${accessToken}`).append('Access-Control-Allow-Origin', '*'),
    });

    return next.handle(header).pipe(
      catchError((error) => {
        this.handleServerExceptions(error);
        return throwError(() => error);
      }),
    );
  }
}
