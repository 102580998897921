<section class="section ms-padding-32">
  <ms-card class="card" noBoxShadow="true">
    <div class="card-container">
      <ms-sidebar width="302" hide-toggle-button="true">
        <div class="sidebar-container">
          <div>
            <ms-title semantic="h5" class="title ms-padding-16">Pesquisa</ms-title>
            <ms-divider></ms-divider>
          </div>
          <div>
            <ms-divider></ms-divider>
            <div class="action">
              <ms-button size="medium" fill="text" disabled="true">Limpar Tudo</ms-button>
              <ms-button size="medium" disabled="true">Filtrar</ms-button>
            </div>
          </div>
        </div>
      </ms-sidebar>
      <div class="content">
        <app-session-expired-message *ngIf="currentRoute === 'session-expired'"></app-session-expired-message>
        <app-internal-server-error-message *ngIf="currentRoute === 'internal-server-error'"></app-internal-server-error-message>
        <app-not-found-message *ngIf="currentRoute === 'not-found'"></app-not-found-message>
        <app-access-denied-message *ngIf="currentRoute === 'unauthorized'"></app-access-denied-message>
      </div>
    </div>
  </ms-card>
</section>
