import { Component } from '@angular/core';
import { IframePostMessageService } from './services/iframe-post-message.service';
import { productFruits } from 'product-fruits';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Construtor
   * @param postMessageService injeta o serviço para lidar com a comunicação com cliente
   */
  constructor(private iframePostMessageService: IframePostMessageService) {
    this.iframePostMessageService.onCaptureMode();
    this.iframePostMessageService.onCacheUniqueKey();
    this.iframePostMessageService.onSyncContactCaptured();
    this.initProductFruits();
  }

  /**
   * Método que inicializa a integração com o serviço do Product Fruits
   */
  private async initProductFruits(): Promise<void> {
    this.iframePostMessageService.onProductFruitsData().then((email) => {
      productFruits.init('XcqFZfBKmERhjgEd', 'pt', { username: email });
    });
  }
}
