<div *ngIf="requestData && requestData.data.length" class="table-container">
  <ms-table
    #tablePeopleRef
    [ngClass]="{ 'table-list': true, 'table-in-box': cacheQuery && cacheQuery.type === queryTypeEnum.CNAE_AND_ROLE }"
    [tableColumns]="columns"
    [tableData]="data"
    [tableEnableSelect]="true"
    [selectAllController]="selectAllController"
    (eChangeOneCheckbox)="onChangeOneCheckbox($event)"
    (eChangeAllCheckbox)="onChangeAllCheckbox($event)"
  ></ms-table>
  <div class="footer">
    <div class="pagination-container">
      <ms-pagination [totalPages]="requestData.meta.pageCount" [currentPage]="paginationPage" [loading]="tablePaginationUpdateLoading" (eChange)="onChangePagination($event)"></ms-pagination>
      <ms-text size="12" color="light" *ngIf="cacheOthers && cacheOthers.linkedinCompanySearch">Não achou o contato que procurava? <ms-link color="secondary" size="12" [href]="'https://www.linkedin.com/search/results/people/?keywords=' + cacheOthers.linkedinCompanySearch" target="_blank">Pesquise no LinkedIn</ms-link></ms-text>
    </div>
    <div class="action">
      <ms-text size="12">{{ listOfContactsSelected.length }} contatos selecionados</ms-text>
      <ms-button size="medium" [disabled]="!listOfContactsSelected.length" [loading]="saveContactsSelectedLoading" (eClick)="onCanContactsCapture()">Salvar Contatos</ms-button>
    </div>
  </div>
</div>

<div class="message-container" *ngIf="!requestData">
  <app-empty-state-message></app-empty-state-message>
</div>

<div class="message-container" *ngIf="requestData && !requestData.data.length">
  <app-no-content-message></app-no-content-message>
</div>

<app-modal-confirm-contact-capture></app-modal-confirm-contact-capture>
