import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PublishContact, RequestData, RequestDataCount } from '../shared/types/contacts';
import { QueryOfContacts } from '../shared/types/components';
import { getQueryParamCompanyId, getQueryParamContactsCount, getQueryParamRoles, getQueryParamCompanyNameAndRole, getQueryParamCnaeAndRole } from '../utils/contact/query-parms';
import { ContactsDatabaseCache } from './contacts-database-cache.service';
import { ObservableSourceType } from '../shared/enums/service';
import { SyncContactsService } from './sync-contacts.service';
import { BehaviorSubjectType } from '../shared/types/service';

@Injectable()
export class ContactsService {
  private paginationSettings!: Record<string, string>;

  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   * @param contactsDatabaseCache injeta o serviço para lidar com o cache de contatos
   * @param syncContactsService injeta o serviço para controlar os observables de sincronização
   */
  constructor(
    private http: HttpClient,
    private contactsDatabaseCache: ContactsDatabaseCache,
    private syncContactsService: SyncContactsService,
  ) {}

  /**
   * Deve chamar o serviço e criar um observable de sincronização para o componente
   * @param componentId id do componente
   */
  execute(componentId: string): void {
    return this.syncContactsService.confireSyncContacts(componentId);
  }

  /**
   * Deve buscar e retornar o observable do componente
   * @param componentId id do componente
   */
  observe(componentId: string): BehaviorSubject<BehaviorSubjectType> {
    return this.syncContactsService.contactsSubjects.get(componentId) as BehaviorSubject<BehaviorSubjectType>;
  }

  /**
   * Deve consumir a api para buscar a lista de cnaes com a quantidade de pessoas
   * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
   * @returns uma lista de canes e a quantidade de pessoas
   */
  getContactsCount(query: QueryOfContacts): Promise<Array<RequestDataCount>> {
    const params = getQueryParamContactsCount(query);
    return firstValueFrom(this.http.get<Array<RequestDataCount>>(`${environment.api}/api/v1/contacts/count`, { params }));
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de pessoas
   * @param componentId id do componente que chamou o serviço
   * @param query atributos de pesquisa ( cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de contatos
   */
  async getContactsByRoles(componentId: string, query: QueryOfContacts, offset = 0, limit = 50, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamRoles(query, offset, limit, isPaginationAction ? this.paginationSettings : null);

    const observableRequest = this.http.get<RequestData>(`${environment.api}/api/v1/contacts/find-by-role`, { params }).pipe(
      switchMap((requestData) => this.contactsDatabaseCache.contactMatchCache(requestData)),
      tap((requestData) => (this.paginationSettings = requestData.meta.settings)),
      tap((requestData) => this.syncContactsService.getComponentSubject(componentId).next({ requestData, observableSourceType: ObservableSourceType.http })),
    );

    await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de empresas
   * @param componentId id do componente que chamou o serviço
   * @param query atributos de pesquisa ( cnaes, estados, cidades )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de contatos
   */
  async getContactsByCompanyId(componentId: string, query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamCompanyId(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.api}/api/v1/companies/${query.companyId}/contacts`, { params }).pipe(
      switchMap((requestData) => this.contactsDatabaseCache.contactMatchCache(requestData)),
      tap((requestData) => (this.paginationSettings = requestData.meta.settings)),
      tap((requestData) => this.syncContactsService.getComponentSubject(componentId).next({ requestData, observableSourceType: ObservableSourceType.http })),
    );

    await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de empresas e pessoas
   * @param componentId id do componente que chamou o serviço
   * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @param isPaginationAction define se deve ser passado os configuração para as paginações seguintes
   * @returns uma lista de contatos
   */
  async getContactsByCnaeAndRole(componentId: string, query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamCnaeAndRole(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.api}/api/v1/contacts`, { params }).pipe(
      switchMap((requestData) => this.contactsDatabaseCache.contactMatchCache(requestData)),
      tap((requestData) => (this.paginationSettings = requestData.meta.settings)),
      tap((requestData) => this.syncContactsService.getComponentSubject(componentId).next({ requestData, observableSourceType: ObservableSourceType.http })),
    );

    await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos filtrados por nome da empresa e cargo
   * @param componentId id do componente que chamou o serviço
   * @param query atributos de pesquisa ( nome da empres e cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @param isPaginationAction define se deve ser passado os configuração para as paginações seguintes
   * @returns uma lista de contatos
   */
  async getContactsByCompanyNameAndRole(componentId: string, query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamCompanyNameAndRole(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.api}/api/v1/contacts`, { params }).pipe(
      switchMap((requestData) => this.contactsDatabaseCache.contactMatchCache(requestData)),
      tap((requestData) => (this.paginationSettings = requestData.meta.settings)),
      tap((requestData) => this.syncContactsService.getComponentSubject(componentId).next({ requestData, observableSourceType: ObservableSourceType.http })),
    );

    await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para publicar a lista de contatos
   * @param publishContact lista de contatos
   * @returns uma lista de contatos
   */
  async publishContacts(publishContact: PublishContact): Promise<PublishContact> {
    const result = await firstValueFrom(this.http.post<PublishContact>(`${environment.api}/api/v1/publish`, publishContact));
    await this.contactsDatabaseCache.publishContactsCache(publishContact);

    return result;
  }
}
