export enum PortRangeEnum {
  RANGE_1_10 = '1-10',
  RANGE_11_50 = '11-50',
  RANGE_51_200 = '51-200',
  RANGE_201_500 = '201-500',
  RANGE_501_1000 = '501-1000',
  RANGE_1001_5000 = '1001-5000',
  RANGE_5001_10000 = '5001-10000',
  RANGE_10000_PLUS = '+10000',
}

export enum EmailReliabilityEnum {
  HIGH_RELIABILITY = 'high',
  MEDIUM_RELIABILITY = 'medium',
  LOW_RELIABILITY = 'low',
  NO_RELIABILITY = 'no_reliability',
}

export enum DisabledReason {
  INFO = 'info',
  DANGER = 'danger',
  TIMER = 'timer',
}
