import { EventEmitter, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactCaptureMode, IframePostMessage, UserAction } from '../shared/enums/post-message';
import { CanContactsCaptureData, SyncContactCapturedEventData, CanContactsCaptureEventData, ContactsCaptureModeEventData, ContactCacheUniqueKeyEventData } from '../shared/types/iframe-post-massage';

@Injectable({
  providedIn: 'root',
})
export class IframePostMessageService {
  private contactCaptureMode: ContactCaptureMode = ContactCaptureMode.standard;
  private renderer: Renderer2;
  private rendererMessageListener!: () => void;

  public syncContactCaptured: EventEmitter<[string, string]> = new EventEmitter();
  public contactCacheUniqueKey = '';

  /**
   * Construtor
   * @param rendererFactory cria e inicializa um renderizador personalizado que implementa a classe base Renderer2
   */
  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Adiciona um evento para receber do cliente o mode de capturas dos contatos
   * standard = captura contatos com validação do interna.
   * specific = captura contatos com validação extena, ao capturar é enviado um evento ('can-contacts-capture') ao cliente pedindo a confirmação para seguir com o processo de capturas.
   */
  async onCaptureMode(): Promise<void> {
    this.renderer.listen('window', 'message', (event: MessageEvent<ContactsCaptureModeEventData>) => {
      if (event.origin !== environment.domain || event.data.type != IframePostMessage.contactsCaptureMode) return;

      this.contactCaptureMode = event.data.validateContactsExternally ? ContactCaptureMode.specific : ContactCaptureMode.standard;
    });
  }

  /**
   * Adiciona um evento para receber do cliente um id para veincular ao cache.
   */
  async onCacheUniqueKey(): Promise<void> {
    this.renderer.listen('window', 'message', (event: MessageEvent<ContactCacheUniqueKeyEventData>) => {
      if (event.origin !== environment.domain || event.data.type != IframePostMessage.contactCacheUniqueKey) return;

      this.contactCacheUniqueKey = event.data.contactCacheUniqueKey;
    });
  }

  /**
   * Adiciona um evento para receber do cliente os contatos que foram processados, para sicronização em tempo do cache e da tela;
   */
  async onSyncContactCaptured(): Promise<void> {
    this.renderer.listen('window', 'message', (event: MessageEvent<SyncContactCapturedEventData>) => {
      if (event.origin !== environment.domain || event.data.type != IframePostMessage.syncContactCaptured) return;
      this.syncContactCaptured.emit([event.data.contactId, event.data.clientName]);
    });
  }

  /**
   * Adiciona um evento para fazer a comunicação com o cliente
   * Uma evento do tipo 'can-contacts-capture' e disparado para o cliente e uma promise e montado esperando sua resposta
   * A finalidade do evento e validar se podemos seguir com a captura dos contatos
   * @param numberOfContacts numero de contatos a serem capturados
   * @returns um objeto com os dados necessário para seguir com a captura;
   */
  canContactsCapture(numberOfContacts: number): Promise<CanContactsCaptureData> {
    return new Promise<CanContactsCaptureData>((resolve, reject) => {
      if (this.contactCaptureMode == ContactCaptureMode.standard) resolve({ contactCaptureMode: this.contactCaptureMode, canCaptureContacts: true, extras: null });

      this.rendererMessageListener = this.renderer.listen('window', 'message', (event: MessageEvent<CanContactsCaptureEventData>) => {
        if (event.origin !== environment.domain || event.data.type != IframePostMessage.canContactsCapture) return;

        if (event.data.canCaptureContacts) {
          resolve({ contactCaptureMode: this.contactCaptureMode, canCaptureContacts: event.data.canCaptureContacts, extras: event.data.extras });
        } else {
          reject();
        }
      });

      if (this.contactCaptureMode == ContactCaptureMode.specific) window.parent.postMessage({ type: IframePostMessage.canContactsCapture, numberOfContacts }, environment.domain);
    }).finally(() => this.rendererMessageListener());
  }

  /**
   * Dispara um evento de log com as ações do usuário
   */
  userActionLogger(userAction: UserAction, data: unknown): void {
    window.parent.postMessage({ type: userAction, data }, environment.domain);
  }
}
