<div *ngIf="!showCompanyDetail && requestData && requestData.data.length" class="table-container">
  <ms-table [ngClass]="{ 'table-list': true, 'table-in-box': cacheQuery && cacheQuery.type === queryTypeEnum.CNAE }" #tableCompanyRef [tableColumns]="columns" [tableData]="data" (eChangeRedirect)="onRedirectDetail($event)" table-enable-redirect></ms-table>
  <div class="footer">
    <ms-pagination [totalPages]="requestData.meta.pageCount" [currentPage]="paginationPage" [loading]="tablePaginationUpdateLoading" (eChange)="onChangePagination($event)"></ms-pagination>
  </div>
</div>

<div class="message-container" *ngIf="!showCompanyDetail && !requestData">
  <app-empty-state-message></app-empty-state-message>
</div>

<div class="message-container" *ngIf="!showCompanyDetail && requestData && !requestData.data.length">
  <app-no-content-message></app-no-content-message>
</div>

<app-company-detail [ngStyle]="{ display: showCompanyDetail ? 'block' : 'none' }" [company]="companyDetailData" [cacheQuery]="cacheQuery" (redirect)="onRedirectList()"></app-company-detail>
