import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IframePostMessageService } from './iframe-post-message.service';
import { ContactsDatabaseCache } from './contacts-database-cache.service';
import { syncListOfContacts } from '../utils/contact/sync-contact-captured ';
import { ObservableSourceType } from '../shared/enums/service';
import { BehaviorSubjectType } from '../shared/types/service';

@Injectable()
export class SyncContactsService {
  public contactsSubjects: Map<string, BehaviorSubject<BehaviorSubjectType>> = new Map();

  /**
   * Construtor
   * @param iframePostMessageService injeta o serviço para lidar com a comunicação com cliente
   * @param contactsDatabaseCache injeta o serviço para lidar com o cache de contatos
   */
  constructor(
    private contactsDatabaseCache: ContactsDatabaseCache,
    private iframePostMessageService: IframePostMessageService,
  ) {
    this.syncContactCaptured();
  }

  /**
   * Deve Criar um observable especifico para o componente
   */
  confireSyncContacts(componentId: string): void {
    this.contactsSubjects.set(componentId, new BehaviorSubject<BehaviorSubjectType>({} as BehaviorSubjectType));
  }

  /**
   * Deve adicionar um listener para atualizar os contatos que já foram capturados
   */
  private syncContactCaptured(): void {
    this.iframePostMessageService.syncContactCaptured.subscribe(([contactId, contactName]) => {
      for (const [key, subject] of this.contactsSubjects.entries()) {
        const { requestData } = subject.getValue() || {};
        if (requestData && requestData.data) {
          this.contactsDatabaseCache.removeContactFromCache(contactId);
          requestData.data = syncListOfContacts(requestData?.data, contactId, contactName);
          subject.next({ requestData, observableSourceType: ObservableSourceType.postMessage });
        }
      }
    });
  }

  /**
   * Deve buscar ou criar um novo observable para o componente
   * @returns o observable referente ao componente
   */
  getComponentSubject(componentId: string): BehaviorSubject<BehaviorSubjectType> {
    if (!this.contactsSubjects.has(componentId)) {
      this.contactsSubjects.set(componentId, new BehaviorSubject<BehaviorSubjectType>({} as BehaviorSubjectType));
    }

    return this.contactsSubjects.get(componentId) as BehaviorSubject<BehaviorSubjectType>;
  }
}
