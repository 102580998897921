import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormDataCnae, FormDataCnaeAndRole, FormDataCompanyName, FormDataCompanyNameAndRole, FormDataRole, sidebarContentForm } from 'src/app/utils/form/form';
import { Item } from '@techramper/monster-web-kit-core/dist/types/components/ms-select/ms-select-interface';
import { AddressesService } from 'src/app/services/addresses.service';
import { CnaesService } from 'src/app/services/cnaes.service';
import { FilterByCnae, FilterByCnaeAndRole, FilterByCompanyName, FilterByCompanyNameAndRole, FilterByRole } from 'src/app/shared/types/components';
import { IframePostMessageService } from 'src/app/services/iframe-post-message.service';
import { UserAction } from 'src/app/shared/enums/post-message';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
})
export class SidebarContentComponent implements OnInit {
  @Output() filterByCnaeAndRole = new EventEmitter<FilterByCnaeAndRole>();
  @Output() filterByCnae = new EventEmitter<FilterByCnae>();
  @Output() filterByCompanyName = new EventEmitter<FilterByCompanyName>();
  @Output() filterByCompanyNameAndRole = new EventEmitter<FilterByCompanyNameAndRole>();
  @Output() filterByRole = new EventEmitter<FilterByRole>();
  @Output() filterClean = new EventEmitter<void>();

  @Input() loading!: boolean;

  protected readonly sector: string = 'https://ramperprospect.movidesk.com/kb/pt-br/article/459234/o-que-e-o-descobrir-contatos-do-ramper-prospect';
  protected readonly reliability: string = 'https://ramperprospect.movidesk.com/kb/pt-br/article/443642/o-que-sao-confiabilidades-de-contatos-no-ramper-prospect';

  protected listOfSectors: Array<Item> = [];
  protected listOfCanes: Array<Item> = [];
  protected listOfStates: Array<Item> = [];
  protected listOfCities: Array<Item> = [];

  protected form = sidebarContentForm;

  /**
   * Construtor
   * @param addressesService injeta a service para obter os inputs de localidade
   * @param cnaesService injeta a service para obter os inputs de cnae's
   * @param iframePostMessageService injeta o serviço para lidar com a comunicação com cliente
   */
  constructor(
    private readonly addressesService: AddressesService,
    private readonly cnaesService: CnaesService,
    private readonly iframePostMessageService: IframePostMessageService,
  ) {}

  /**
   * Callback para o ciclo de vida do componente
   * É invocado apenas uma vez quando a diretiva é instanciada.
   */
  ngOnInit(): void {
    if (this.form.controls.cnaes.invalid) {
      this.form.controls.states.disable();
    }

    if (this.form.controls.cnaes.valid || !this.form.controls.states.valid) {
      this.form.controls.cities.disable();
    }

    void this.findAllGroupsClassesAndCnaes();
    void this.findAllSectorsAndSections();
    void this.findAllStates();
  }

  /**
   * Deve obter a lista de grupos, classes ou cnaes e mapear para o componente ms-select
   */
  private async findAllGroupsClassesAndCnaes(): Promise<void> {
    const groupsClassesAndCnaes = await this.cnaesService.findAllGroupsClassesAndCnaes();
    const cnaes = groupsClassesAndCnaes.flatMap((category) => category.classesCnae.flatMap((classCnae) => classCnae.cnaes));

    this.listOfCanes = cnaes.map((cnae) => {
      return { label: `${cnae.id}: ${cnae.description}`, tag: cnae.id, tooltip: cnae.description, data: cnae.id };
    });
  }

  /**
   * Deve obter a lista de setores e sessões e mapear para o componente ms-select
   */
  private async findAllSectorsAndSections(): Promise<void> {
    const sectorsAndSections = await this.cnaesService.findAllSectorsAndSections();
    const sectors = sectorsAndSections.map((section) => section.sectors).flat();

    this.listOfSectors = sectors.map((sector, index) => {
      return { label: `${sector.id}: ${sector.description}`, tag: sector.id, tooltip: sector.description, data: sector.id };
    });
  }

  /**
   * Deve obter a lista de estados e mapear para o componente ms-select
   */
  private async findAllStates(): Promise<void> {
    const states = await this.addressesService.findAllStates();

    this.listOfStates = states.map((state) => {
      return { label: `(${state.uf}) ${state.description}`, tag: state.uf, tooltip: state.description, data: state.uf };
    });
  }

  /**
   * Deve obter a lista de estados e mapear para o componente ms-select
   */
  private async findAllCities(): Promise<void> {
    const states = this.form.controls.states.value as unknown as Array<string>;
    const cities = await this.addressesService.findAllCities(states[0]);

    this.listOfCities = cities.map((city) => {
      return { label: city.name, data: city.id };
    });
  }

  /**
   * Deve limpar os dados imputados no input (setores e cnaes)
   */
  protected onCleanCnaes(): void {
    this.form.controls.cnaes?.setValue([]);

    this.form.controls.states?.setValue([]);
    this.form.controls.states.disable();

    this.form.controls.cities?.setValue([]);
    this.form.controls.cities.disable();

    this.form.controls.companyName.enable();
  }

  /**
   * Deve limpar os dados imputados no input (estados e cidades)
   */
  protected onCleanStates(): void {
    this.form.controls.states?.setValue([]);
    this.form.controls.cities?.setValue([]);
    this.form.controls.cities.disable();
  }

  /**
   * Deve limpar os dados imputados no input (cidades)
   */
  protected onCleanCities(): void {
    this.form.controls.cities?.setValue([]);
  }

  /**
   * Deve limpar os dados imputados no input (cargos)
   */
  protected onCleanRoles(): void {
    this.form.controls.roles?.setValue([]);
  }

  /**
   * Deve limpar os dados imputados no input (setores e cnaes)
   */
  protected onButtonToggleChange(): void {
    this.onCleanCnaes();
  }

  /**
   * Callback para o evento eChange do select (setores e cnaes)
   */
  protected onSelectCnaesInput(): void {
    if (this.form.controls.cnaes.valid) {
      this.form.controls.companyName.setValue('');
      this.form.controls.companyName.disable();
      this.form.controls.states.enable();

      return;
    }

    this.onCleanCnaes();
  }

  /**
   * Callback para o evento eInput do ms-input
   */
  protected onCompanyNameInput(): void {
    if (this.form.controls.companyName.value?.length) {
      this.form.controls.cnaes?.setValue([]);
      this.form.controls.cnaes.disable();
    }

    if (!this.form.controls.companyName.value?.length) {
      this.form.controls.toggle.enable();
      this.form.controls.cnaes.enable();
    }
  }

  /**
   * Callback para o evento eChange do select
   */
  protected onSelectStatesInput(): void {
    const formControlStateData = this.form.controls.states.value as unknown as Array<string>;

    if ((this.form.controls.states.valid && formControlStateData.length >= 2) || (this.form.controls.states.valid && formControlStateData.length == 0)) {
      this.listOfCities = [];
      this.form.controls.cities.setValue([]);
      this.form.controls.cities.disable();

      return;
    }

    if (this.form.controls.states.valid) {
      this.form.controls.cities.enable();
      this.findAllCities();

      return;
    }

    this.form.controls.cities?.setValue([]);
    this.form.controls.cities.disable();
  }

  /**
   * Deve validar o formulário e fazer a busca de empresas ou contato
   */
  protected onFilter(): void {
    if (this.form.controls.companyName.valid && this.form.controls.roles.valid) {
      const formCompanyNameAndRole = FormDataCompanyNameAndRole(this.form);

      this.filterByCompanyNameAndRole.emit(formCompanyNameAndRole);
      this.iframePostMessageService.userActionLogger(UserAction.FILTER_COMPANY_NAME_AND_ROLE, formCompanyNameAndRole);

      return;
    }

    if (this.form.controls.companyName.valid) {
      const formCompanyName = FormDataCompanyName(this.form);

      this.filterByCompanyName.emit(formCompanyName);
      this.iframePostMessageService.userActionLogger(UserAction.FILTER_COMPANY_NAME, formCompanyName);

      return;
    }

    if (this.form.controls.cnaes.valid && this.form.controls.roles.valid) {
      const formCnaeAndRole = FormDataCnaeAndRole(this.form);

      this.filterByCnaeAndRole.emit(formCnaeAndRole);
      this.iframePostMessageService.userActionLogger(UserAction.FILTER_CNAE_AND_ROLE, formCnaeAndRole);

      return;
    }

    if (this.form.controls.cnaes.valid) {
      const formCnaes = FormDataCnae(this.form);

      this.filterByCnae.emit(formCnaes);
      this.iframePostMessageService.userActionLogger(UserAction.FILTER_CNAE, formCnaes);

      return;
    }

    if (this.form.controls.roles.valid) {
      const formRole = FormDataRole(this.form);

      this.filterByRole.emit(formRole);
      this.iframePostMessageService.userActionLogger(UserAction.FILTER_ROLE, formRole);

      return;
    }
  }

  /**
   * Deve limpar o formulário
   */
  protected onClean(): void {
    this.form.reset();

    this.form.controls.companyName.enable();
    this.form.controls.cnaes.enable();

    this.form.controls.states.disable();
    this.form.controls.cities.disable();

    this.form.controls.toggle.setValue('1');

    this.filterClean.emit();
  }
}
