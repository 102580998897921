<div #container *ngIf="requestDataCount && requestDataCount.data.length" class="container">
  <div #listExpansionCardComponent class="list-item" *ngFor="let cnae of requestDataCount.data; let i = index">
    <ms-expansion-card class="card-container" #card [handleControllerWithManual]="true" [cardId]="cnae.id" [loading]="showExpansionCardLoading === cnae.id" divider="false" [disabled]="!cnae.quantity" (eChangeCard)="onChangeCard($event, cnae.id)">
      <span class="card-title" slot="title">
        <span [ngClass]="{ disabled: !cnae.quantity }" class="tag">{{ cnae.id }}</span> {{ cnae.description }} ( {{ cnae.quantity ? cnae.quantity : 'Sem' }} Resultados )
      </span>
      <div class="card-content" slot="content" [style]="{ height: container.offsetHeight - 50 - requestDataCount.data.length * 30 + 'px' }">
        <div [hidden]="!card.open" [style]="{ height: container.offsetHeight - 50 - requestDataCount.data.length * 30 + 'px' }">
          <app-table-company [tableCompanyId]="cnae.id"></app-table-company>
        </div>
      </div>
    </ms-expansion-card>
    <ms-divider></ms-divider>
  </div>
</div>

<div class="message-container" *ngIf="!requestDataCount">
  <app-empty-state-message></app-empty-state-message>
</div>

<div class="message-container" *ngIf="requestDataCount && !requestDataCount.data.length">
  <app-no-content-message></app-no-content-message>
</div>
