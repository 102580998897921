import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EmailReliabilityEnum, PortRangeEnum } from 'src/app/shared/enums/contacts';
import { FilterByCnae, FilterByCnaeAndRole, FilterByCompanyName, FilterByCompanyNameAndRole, FilterByRole } from 'src/app/shared/types/components';

/**
 *  Deve realizar a verificação se é uma lista vazia
 * @param control controle do formulário
 * @returns um status se o form control é invalido, se não, retorna nulo
 */
export function noEmptyListValidator(control: AbstractControl): ValidationErrors | null {
  const list = control.value as Array<any>;

  if (!list || !Array.isArray(list) || list.length === 0) {
    return { noEmptyList: true };
  }

  return null;
}
/**
 * Deve montar o formulário da sidebar
 */
export const sidebarContentForm = new FormGroup({
  companyName: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(4)])),
  toggle: new FormControl('1', Validators.required),
  cnaes: new FormControl({ value: [], disabled: false }, noEmptyListValidator),
  roles: new FormControl({ value: [], disabled: false }, noEmptyListValidator),
  states: new FormControl({ value: [], disabled: false }),
  cities: new FormControl({ value: [], disabled: false }),
  port: new FormGroup({
    range_1_10: new FormControl({ value: false, disabled: false }),
    range_11_50: new FormControl({ value: false, disabled: false }),
    range_51_200: new FormControl({ value: false, disabled: false }),
    range_201_500: new FormControl({ value: false, disabled: false }),
    range_501_1000: new FormControl({ value: false, disabled: false }),
    range_1001_5000: new FormControl({ value: false, disabled: false }),
    range_5001_10000: new FormControl({ value: false, disabled: false }),
    range_10000_plus: new FormControl({ value: false, disabled: false }),
  }),
  confiability: new FormGroup({
    high: new FormControl({ value: false, disabled: false }),
    medium: new FormControl({ value: false, disabled: false }),
    low: new FormControl({ value: false, disabled: false }),
    none: new FormControl({ value: false, disabled: false }),
  }),
});

/**
 * Deve obter os dados nescessários para buscar por Cnae e Cargo
 * @returns Um objeto com os dados de busca
 */
export function FormDataCnaeAndRole(form: typeof sidebarContentForm): FilterByCnaeAndRole {
  const cnaes = form.controls.cnaes.value;
  const toggle = form.controls.toggle.value;
  const states = form.controls.states.value;
  const cities = form.controls.cities.value;
  const roles = form.controls.roles.value;

  const range_1_10 = form.controls.port.controls.range_1_10.value ? PortRangeEnum.RANGE_1_10 : '';
  const range_11_50 = form.controls.port.controls.range_11_50.value ? PortRangeEnum.RANGE_11_50 : '';
  const range_51_200 = form.controls.port.controls.range_51_200.value ? PortRangeEnum.RANGE_51_200 : '';
  const range_201_500 = form.controls.port.controls.range_201_500.value ? PortRangeEnum.RANGE_201_500 : '';
  const range_501_1000 = form.controls.port.controls.range_501_1000.value ? PortRangeEnum.RANGE_501_1000 : '';
  const range_1001_5000 = form.controls.port.controls.range_1001_5000.value ? PortRangeEnum.RANGE_1001_5000 : '';
  const range_5001_10000 = form.controls.port.controls.range_5001_10000.value ? PortRangeEnum.RANGE_5001_10000 : '';
  const range_10000_plus = form.controls.port.controls.range_10000_plus.value ? PortRangeEnum.RANGE_10000_PLUS : '';
  const port = [range_1_10, range_11_50, range_51_200, range_201_500, range_501_1000, range_1001_5000, range_5001_10000, range_10000_plus].filter((item) => item != '');

  const high = form.controls.confiability.controls.high.value ? EmailReliabilityEnum.HIGH_RELIABILITY : '';
  const medium = form.controls.confiability.controls.medium.value ? EmailReliabilityEnum.MEDIUM_RELIABILITY : '';
  const low = form.controls.confiability.controls.low.value ? EmailReliabilityEnum.LOW_RELIABILITY : '';
  const none = form.controls.confiability.controls.none.value ? EmailReliabilityEnum.NO_RELIABILITY : '';
  const reliability = [high, medium, low, none].filter((item) => item != '');

  const formCnae = { cnaes, toggle, states, cities, port, reliability } as FilterByCnae;
  const formRole = { roles, port, reliability } as FilterByRole;

  return { ...formCnae, ...formRole } as FilterByCnaeAndRole;
}

/**
 * Deve obter os dados nescessários para buscar por Cnae
 * @returns Um objeto com os dados de busca
 */
export function FormDataCnae(form: typeof sidebarContentForm): FilterByCnae {
  const cnaes = form.controls.cnaes.value;
  const toggle = form.controls.toggle.value;
  const states = form.controls.states.value;
  const cities = form.controls.cities.value;

  const range_1_10 = form.controls.port.controls.range_1_10.value ? PortRangeEnum.RANGE_1_10 : '';
  const range_11_50 = form.controls.port.controls.range_11_50.value ? PortRangeEnum.RANGE_11_50 : '';
  const range_51_200 = form.controls.port.controls.range_51_200.value ? PortRangeEnum.RANGE_51_200 : '';
  const range_201_500 = form.controls.port.controls.range_201_500.value ? PortRangeEnum.RANGE_201_500 : '';
  const range_501_1000 = form.controls.port.controls.range_501_1000.value ? PortRangeEnum.RANGE_501_1000 : '';
  const range_1001_5000 = form.controls.port.controls.range_1001_5000.value ? PortRangeEnum.RANGE_1001_5000 : '';
  const range_5001_10000 = form.controls.port.controls.range_5001_10000.value ? PortRangeEnum.RANGE_5001_10000 : '';
  const range_10000_plus = form.controls.port.controls.range_10000_plus.value ? PortRangeEnum.RANGE_10000_PLUS : '';
  const port = [range_1_10, range_11_50, range_51_200, range_201_500, range_501_1000, range_1001_5000, range_5001_10000, range_10000_plus].filter((item) => item != '');

  const high = form.controls.confiability.controls.high.value ? EmailReliabilityEnum.HIGH_RELIABILITY : '';
  const medium = form.controls.confiability.controls.medium.value ? EmailReliabilityEnum.MEDIUM_RELIABILITY : '';
  const low = form.controls.confiability.controls.low.value ? EmailReliabilityEnum.LOW_RELIABILITY : '';
  const none = form.controls.confiability.controls.none.value ? EmailReliabilityEnum.NO_RELIABILITY : '';
  const reliability = [high, medium, low, none].filter((item) => item != '');

  return { cnaes, toggle, states, cities, port, reliability } as FilterByCnae;
}

/**
 * Deve obter os dados nescessários para buscar por Cargo
 * @returns Um objeto com os dados de busca
 */
export function FormDataRole(form: typeof sidebarContentForm): FilterByRole {
  const roles = form.controls.roles.value;

  const range_1_10 = form.controls.port.controls.range_1_10.value ? PortRangeEnum.RANGE_1_10 : '';
  const range_11_50 = form.controls.port.controls.range_11_50.value ? PortRangeEnum.RANGE_11_50 : '';
  const range_51_200 = form.controls.port.controls.range_51_200.value ? PortRangeEnum.RANGE_51_200 : '';
  const range_201_500 = form.controls.port.controls.range_201_500.value ? PortRangeEnum.RANGE_201_500 : '';
  const range_501_1000 = form.controls.port.controls.range_501_1000.value ? PortRangeEnum.RANGE_501_1000 : '';
  const range_1001_5000 = form.controls.port.controls.range_1001_5000.value ? PortRangeEnum.RANGE_1001_5000 : '';
  const range_5001_10000 = form.controls.port.controls.range_5001_10000.value ? PortRangeEnum.RANGE_5001_10000 : '';
  const range_10000_plus = form.controls.port.controls.range_10000_plus.value ? PortRangeEnum.RANGE_10000_PLUS : '';
  const port = [range_1_10, range_11_50, range_51_200, range_201_500, range_501_1000, range_1001_5000, range_5001_10000, range_10000_plus].filter((item) => item != '');

  const high = form.controls.confiability.controls.high.value ? EmailReliabilityEnum.HIGH_RELIABILITY : '';
  const medium = form.controls.confiability.controls.medium.value ? EmailReliabilityEnum.MEDIUM_RELIABILITY : '';
  const low = form.controls.confiability.controls.low.value ? EmailReliabilityEnum.LOW_RELIABILITY : '';
  const none = form.controls.confiability.controls.none.value ? EmailReliabilityEnum.NO_RELIABILITY : '';
  const reliability = [high, medium, low, none].filter((item) => item != '');

  return { roles, port, reliability } as FilterByRole;
}

/**
 * Deve obter os dados nescessários para buscar por nome de empresa
 * @returns Um objeto com os dados de busca
 */
export function FormDataCompanyName(form: typeof sidebarContentForm): FilterByCompanyName {
  const companyName = form.controls.companyName.value;

  const range_1_10 = form.controls.port.controls.range_1_10.value ? PortRangeEnum.RANGE_1_10 : '';
  const range_11_50 = form.controls.port.controls.range_11_50.value ? PortRangeEnum.RANGE_11_50 : '';
  const range_51_200 = form.controls.port.controls.range_51_200.value ? PortRangeEnum.RANGE_51_200 : '';
  const range_201_500 = form.controls.port.controls.range_201_500.value ? PortRangeEnum.RANGE_201_500 : '';
  const range_501_1000 = form.controls.port.controls.range_501_1000.value ? PortRangeEnum.RANGE_501_1000 : '';
  const range_1001_5000 = form.controls.port.controls.range_1001_5000.value ? PortRangeEnum.RANGE_1001_5000 : '';
  const range_5001_10000 = form.controls.port.controls.range_5001_10000.value ? PortRangeEnum.RANGE_5001_10000 : '';
  const range_10000_plus = form.controls.port.controls.range_10000_plus.value ? PortRangeEnum.RANGE_10000_PLUS : '';
  const port = [range_1_10, range_11_50, range_51_200, range_201_500, range_501_1000, range_1001_5000, range_5001_10000, range_10000_plus].filter((item) => item != '');

  const high = form.controls.confiability.controls.high.value ? EmailReliabilityEnum.HIGH_RELIABILITY : '';
  const medium = form.controls.confiability.controls.medium.value ? EmailReliabilityEnum.MEDIUM_RELIABILITY : '';
  const low = form.controls.confiability.controls.low.value ? EmailReliabilityEnum.LOW_RELIABILITY : '';
  const none = form.controls.confiability.controls.none.value ? EmailReliabilityEnum.NO_RELIABILITY : '';
  const reliability = [high, medium, low, none].filter((item) => item != '');

  return { companyName, port, reliability } as FilterByCompanyName;
}

/**
 * Deve obter os dados nescessários para buscar por Nome da empresa e Cargo
 * @returns Um objeto com os dados de busca
 */
export function FormDataCompanyNameAndRole(form: typeof sidebarContentForm): FilterByCompanyNameAndRole {
  const companyName = form.controls.companyName.value;
  const roles = form.controls.roles.value;

  const range_1_10 = form.controls.port.controls.range_1_10.value ? PortRangeEnum.RANGE_1_10 : '';
  const range_11_50 = form.controls.port.controls.range_11_50.value ? PortRangeEnum.RANGE_11_50 : '';
  const range_51_200 = form.controls.port.controls.range_51_200.value ? PortRangeEnum.RANGE_51_200 : '';
  const range_201_500 = form.controls.port.controls.range_201_500.value ? PortRangeEnum.RANGE_201_500 : '';
  const range_501_1000 = form.controls.port.controls.range_501_1000.value ? PortRangeEnum.RANGE_501_1000 : '';
  const range_1001_5000 = form.controls.port.controls.range_1001_5000.value ? PortRangeEnum.RANGE_1001_5000 : '';
  const range_5001_10000 = form.controls.port.controls.range_5001_10000.value ? PortRangeEnum.RANGE_5001_10000 : '';
  const range_10000_plus = form.controls.port.controls.range_10000_plus.value ? PortRangeEnum.RANGE_10000_PLUS : '';
  const port = [range_1_10, range_11_50, range_51_200, range_201_500, range_501_1000, range_1001_5000, range_5001_10000, range_10000_plus].filter((item) => item != '');

  const high = form.controls.confiability.controls.high.value ? EmailReliabilityEnum.HIGH_RELIABILITY : '';
  const medium = form.controls.confiability.controls.medium.value ? EmailReliabilityEnum.MEDIUM_RELIABILITY : '';
  const low = form.controls.confiability.controls.low.value ? EmailReliabilityEnum.LOW_RELIABILITY : '';
  const none = form.controls.confiability.controls.none.value ? EmailReliabilityEnum.NO_RELIABILITY : '';
  const reliability = [high, medium, low, none].filter((item) => item != '');

  return { companyName, roles, port, reliability } as FilterByCompanyNameAndRole;
}
