import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRange',
})
export class FormatRangePipe implements PipeTransform {
  /**
   * Deve adicionar casa decimal ao texto numérico
   * @param value valor a ser formatado
   * @returns o valorm formatado
   */
  transform(value: string): string {
    if (!value) return '';

    return value.replace(/\d+/g, (number) => {
      return parseInt(number, 10).toLocaleString('pt-BR');
    });
  }
}
