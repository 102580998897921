<section class="section ms-padding-32">
  <ms-card class="card" noBoxShadow="true">
    <div class="card-container">
      <!-- FORMULÁRIO COM OS FILTROS -->
      <ms-sidebar width="310" sidebar-title="Pesquisa" enable-collapse="true">
        <app-sidebar-content
          (filterByRole)="onFilterByRole($event)"
          (filterByCnae)="onFilterByCnae($event)"
          (filterByCnaeAndRole)="onFilterByCnaeAndRole($event)"
          (filterByCompanyName)="onFilterByCompanyName($event)"
          (filterByCompanyNameAndRole)="onFilterByCompanyNameAndRole($event)"
          (filterClean)="onFilterClean()"
          [loading]="filterLoading"
        ></app-sidebar-content>
      </ms-sidebar>

      <!-- TABELA DE CONTATOS -->

      <div class="content" *ngIf="currentComponentView === currentComponentViewEnum.PEOPLE">
        <app-table-people tablePeopleId="tablePeople"></app-table-people>
      </div>

      <!-- TABELA DE CONTATOS AGRUPADOS POR CNAE -->

      <div class="content" *ngIf="currentComponentView === currentComponentViewEnum.CNAE_AND_PEOPLE">
        <app-table-cnae-and-people></app-table-cnae-and-people>
      </div>

      <!-- TABELA DE EMPRESAS -->

      <div class="content" *ngIf="currentComponentView === currentComponentViewEnum.COMPANY">
        <app-table-company tableCompanyId="tablecompany"></app-table-company>
      </div>

      <!-- TABELA DE EMPRESAS AGRUPADAS POR CNAE -->

      <div class="content" *ngIf="currentComponentView === currentComponentViewEnum.CNAE_AND_COMPANY">
        <app-table-cnae-and-company></app-table-cnae-and-company>
      </div>
    </div>
  </ms-card>
</section>
