import { NgModule } from '@angular/core';
import { CaptureComponent } from './capture.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppMsSelectDirective } from 'src/app/shared/directives/app-ms-select.directive';
import { AppMsCheckboxDirective } from 'src/app/shared/directives/app-ms-checkbox.directive';
import { RouterModule, Routes } from '@angular/router';
import { SidebarContentComponent } from './partials/sidebar-content/sidebar-content.component';
import { EmptyStateMessageComponent } from '../../components/empty-state-message/empty-state-message.component';
import { AppMsSearchMultipleDirective } from 'src/app/shared/directives/app-ms-search-multiple.directive';
import { CommonModule } from '@angular/common';
import { AppMsButtonToggleDirective } from 'src/app/shared/directives/app-ms-button-toggle.directive';
import { AddressesService } from 'src/app/services/addresses.service';
import { CnaesService } from 'src/app/services/cnaes.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { TablePeopleComponent } from './partials/table-people/table-people.component';
import { NoContentMessageComponent } from 'src/app/components/no-content-message/no-content-message.component';
import { ModalConfirmContactCaptureComponent } from 'src/app/components/modal-confirm-contact-capture/modal-confirm-contact-capture.component';
import { ContactsDatabaseCache } from 'src/app/services/contacts-database-cache.service';
import { SyncContactsService } from 'src/app/services/sync-contacts.service';
import { AppMsInputDirective } from 'src/app/shared/directives/app-ms-input.directive';
import { TableCnaeAndPeopleComponent } from './partials/table-cnae-and-people/table-cnae-and-people.component';
import { TableCnaeAndCompanyComponent } from './partials/table-cnae-and-company/table-cnae-and-company.component';
import { TableCompanyComponent } from './partials/table-company/table-company.component';
import { CompanyDetailComponent } from './partials/company-detail/company-detail.component';
import { MonsterWebKitModule } from 'src/app/monster-web-kit.module';
import { FormatRangePipe } from 'src/app/shared/pipes/format-range.pipe';

const routes: Routes = [
  {
    path: '',
    component: CaptureComponent,
  },
];

@NgModule({
  declarations: [
    CaptureComponent,
    AppMsSelectDirective,
    AppMsCheckboxDirective,
    AppMsSearchMultipleDirective,
    AppMsButtonToggleDirective,
    AppMsInputDirective,
    EmptyStateMessageComponent,
    NoContentMessageComponent,
    SidebarContentComponent,
    TablePeopleComponent,
    TableCnaeAndCompanyComponent,
    TableCnaeAndPeopleComponent,
    TableCompanyComponent,
    ModalConfirmContactCaptureComponent,
    CompanyDetailComponent,
    FormatRangePipe,
  ],
  imports: [CommonModule, RouterModule.forChild(routes), ReactiveFormsModule, HttpClientModule, MonsterWebKitModule],
  providers: [AddressesService, CnaesService, ContactsService, ContactsDatabaseCache, CompaniesService, SyncContactsService],
})
export class CaptureModule {}
