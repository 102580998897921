export enum IframePostMessage {
  contactsCaptureMode = 'contacts-capture-mode',
  contactCacheUniqueKey = 'contacts-cache-unique-key',
  canContactsCapture = 'can-contacts-capture',
  syncContactCaptured = 'sync-contact-captured',
  productFruitsData = 'product-fruits-data',
}

export enum ContactCaptureMode {
  standard = 'standard',
  specific = 'specific',
}

export enum UserAction {
  FILTER_COMPANY_NAME = 'USER_ACTION_FILTER_COMPANY_NAME',
  FILTER_COMPANY_NAME_AND_ROLE = 'USER_ACTION_FILTER_COMPANY_NAME_AND_ROLE',
  FILTER_CNAE = 'USER_ACTION_FILTER_CNAE',
  FILTER_ROLE = 'USER_ACTION_FILTER_ROLE',
  FILTER_CNAE_AND_ROLE = 'USER_ACTION_FILTER_CNAE_AND_ROLE',
  CAPTURE_CONTACT = 'USER_ACTION_CAPTURE_CONTACT',
}
