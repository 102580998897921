import { TextTooltipStructure } from '@techramper/monster-web-kit-core/dist/types/components/ms-table/ms-table-interface';

/**
 * Deve criar uma estrutura que represente o componente text-tooltip
 */
export function createContactTextTooltipComponent(text: string, tooltip: string): TextTooltipStructure | undefined {
  return {
    type: 'text-tooltip',
    content: text,
    tooltip: tooltip,
  } as TextTooltipStructure;
}
