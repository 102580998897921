import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InputEventDetail } from '@techramper/monster-web-kit-core/loader';
import { Company } from 'src/app/shared/types/company';
import { TablePeopleComponent } from '../table-people/table-people.component';
import { ContactQueryType } from 'src/app/shared/enums/components';
import { formatCompanyNameToLinkedIn } from 'src/app/utils/company/format-company-name';
import { QueryOfCompanies } from 'src/app/shared/types/components';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
})
export class CompanyDetailComponent {
  @ViewChild(TablePeopleComponent, { static: false }) tablePeopleComponent!: TablePeopleComponent;

  @Input({ required: true }) company: Company | null = null;
  @Input({ required: true }) cacheQuery: QueryOfCompanies | null = null;

  @Output() redirect = new EventEmitter<void>();

  protected showInputSearchLoading = false;

  private cacheSearchTimeout!: ReturnType<typeof setTimeout>;

  /**
   * Deve listar todos os comtatos da empresa em tela
   */
  async getContacts(): Promise<void> {
    await this.tablePeopleComponent.getContacts({ type: ContactQueryType.COMPANY_AND_ROLE, companyId: this.company?.id, reliability: this.cacheQuery?.reliability }, { linkedinCompanySearch: formatCompanyNameToLinkedIn(this.company?.name || '') });
  }

  /**
   *  Callback do componente ms-input ao realizar ação de pesquisa por cargo
   * @param event dados do evento
   */
  protected onInputSearch(event: Event): void {
    const data = (event as CustomEvent).detail as InputEventDetail;
    const value = data.value?.trim() || '';

    if (value.length == 0 || value.length > 2) {
      clearTimeout(this.cacheSearchTimeout);

      this.cacheSearchTimeout = setTimeout(
        async () => {
          this.showInputSearchLoading = true;
          await this.tablePeopleComponent.getContacts({ type: ContactQueryType.COMPANY_AND_ROLE, companyId: this.company?.id, roles: [value], reliability: this.cacheQuery?.reliability }, { linkedinCompanySearch: formatCompanyNameToLinkedIn(this.company?.name || '') });
          this.showInputSearchLoading = false;
        },
        value ? 500 : 0,
      );
    }
  }

  /**
   * Deve retornar o link completo para buscar a logo da empresa
   */
  protected getImageLink(): string {
    const domain = this.company?.domains[0].replace(/^https?:\/\//i, '');
    return domain ? `https://www.google.com/s2/favicons?domain=${domain}&sz=128` : '';
  }
}
