import { DisabledReason } from 'src/app/shared/enums/contacts';
import { Contact } from 'src/app/shared/types/contacts';

/**
 * Deve criar uma estrutura que represente o componente tag
 */
export function syncListOfContacts(contats: Array<Contact>, contactId: string, contactName: string): Array<Contact> {
  return contats.map((contact) => {
    if (contact.disabled && contact.id === contactId && contact.disabledReason?.type === DisabledReason.TIMER) {
      return {
        ...contact,
        disabledReason: {
          description: `Contato capturado por ${contactName}`,
          title: 'Contato já capturado!',
          type: DisabledReason.INFO,
        },
      };
    }

    return contact;
  });
}
