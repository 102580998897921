import { Component, ElementRef, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { RequestDataCount } from 'src/app/shared/types/company';
import { FilterByCnae } from 'src/app/shared/types/components';
import { CompanyQueryType } from 'src/app/shared/enums/components';
import { TableCompanyComponent } from '../table-company/table-company.component';

@Component({
  selector: 'app-table-cnae-and-company',
  templateUrl: './table-cnae-and-company.component.html',
  styleUrls: ['./table-cnae-and-company.component.scss'],
})
export class TableCnaeAndCompanyComponent {
  @ViewChildren('listExpansionCardComponent') listExpansionCardComponent!: QueryList<ElementRef>;
  @ViewChildren(TableCompanyComponent) tableCompanyComponents!: QueryList<TableCompanyComponent>;

  protected requestDataCount: { data: Array<RequestDataCount> } | null = null;
  protected expansionCardElement!: HTMLMsExpansionCardElement | null;
  protected tableCompanyComponent!: TableCompanyComponent | undefined;
  protected showExpansionCardLoading: string | null = null;

  private cacheFilterByCnae!: FilterByCnae;
  private cacheCompaniesInMemory: Array<string> = [];

  /**
   * Construtor
   * @param companiesService injeta o serviço para lidar com empresas
   * @param changeDetectorRef injeta o serviço para lidar com atualização dos componentes filhos
   */
  constructor(
    private companiesService: CompaniesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  /**
   * Deve buscar a quantidade de empresas por cnae
   */
  public async getCompanyCount(filterByCnae: FilterByCnae): Promise<void> {
    this.cacheFilterByCnae = filterByCnae;

    this.requestDataCount = { data: await this.companiesService.getCompanyCount({ type: CompanyQueryType.CNAE, ...filterByCnae }) };

    this.cleanTableState(true);

    const index = this.requestDataCount.data.findIndex((item) => item.quantity > 0);

    if (index >= 0) {
      await this.showTableCompanyComponent(this.requestDataCount.data[index].id);

      this.expansionCardElement = (this.listExpansionCardComponent.get(index)?.nativeElement as HTMLDivElement).querySelector<HTMLMsExpansionCardElement>(`ms-expansion-card`);
      this.expansionCardElement?.state(true);
    }
  }

  /**
   * Deve apresentar a tabela com a lista de empresas
   */
  private async showTableCompanyComponent(cnae: string): Promise<void> {
    this.tableCompanyComponent?.cleanTableState(true);

    if (!this.cacheCompaniesInMemory.find((item) => item == cnae)) {
      this.showExpansionCardLoading = cnae;

      this.changeDetectorRef.detectChanges();

      this.tableCompanyComponent = this.tableCompanyComponents.find((componente) => componente.tableCompanyId === cnae);
      await this.tableCompanyComponent?.getCompanies({ type: CompanyQueryType.CNAE, ...this.cacheFilterByCnae, cnaes: [cnae] });

      this.cacheCompaniesInMemory.push(cnae);

      this.showExpansionCardLoading = null;

      return;
    }
  }

  /**
   * Deve limpar limpar o estado do componente
   */
  public cleanTableState(notCleanRequestDataCount?: boolean): void {
    if (!notCleanRequestDataCount) this.requestDataCount = null;

    this.expansionCardElement = null;
    this.cacheCompaniesInMemory = [];
  }

  /**
   * Callback do componente ms-expansion-card ao clicar para que seja aberto/fechado
   * @param event dados do evento
   * @param cnae id do cnae referente ao card
   */
  protected async onChangeCard(event: Event, cnae: string): Promise<void> {
    const data = (event as CustomEvent<{ open: boolean; id: string }>).detail;

    if (!this.showExpansionCardLoading && this.expansionCardElement?.cardId === data.id) {
      this.expansionCardElement?.state(!data.open);
    }

    if (!this.showExpansionCardLoading && this.expansionCardElement?.cardId !== data.id) {
      this.expansionCardElement?.state(false);

      await this.showTableCompanyComponent(cnae);

      this.expansionCardElement = event.target as HTMLMsExpansionCardElement;
      setTimeout(() => this.expansionCardElement?.state(true), 50);
    }
  }
}
