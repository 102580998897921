import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { columnsCompanyByCnae, columnsCompanyByCompanyName } from 'src/app/shared/constants/components';
import { Company, RequestDataCompany } from 'src/app/shared/types/company';
import { formatCompanyNameToLinkedIn } from 'src/app/utils/company/format-company-name';
import { CompanyDetailComponent } from '../company-detail/company-detail.component';
import { QueryOfCompanies } from 'src/app/shared/types/components';
import { CompanyQueryType } from 'src/app/shared/enums/components';

@Component({
  selector: 'app-table-company',
  templateUrl: './table-company.component.html',
  styleUrls: ['./table-company.component.scss'],
})
export class TableCompanyComponent {
  @Input({ required: true }) tableCompanyId!: string;

  @ViewChild('tableCompanyRef') table!: { el: HTMLMsTableElement };
  @ViewChild(CompanyDetailComponent, { static: false }) companyDetailComponent!: CompanyDetailComponent;

  protected columns: Array<unknown> = [];
  protected requestData!: RequestDataCompany | null;
  protected data: Array<Record<string, unknown>> = [];
  protected paginationPage = 1;
  protected tablePaginationUpdateLoading!: boolean;
  protected cacheQuery!: QueryOfCompanies;
  protected showCompanyDetail = false;
  protected companyDetailData: Company | null = null;
  protected companyRedirectLoadingById: string | null = null;
  protected queryTypeEnum = CompanyQueryType;

  /**
   * Construtor
   * @param companiesService injeta o serviço para lidar com empresas
   * @param changeDetectorRef injeta o serviço para lidar com atualização dos componentes filhos
   */
  constructor(
    private companiesService: CompaniesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  /**
   * Callback e disparada quando é aplicado um novo filtro para buscar por cargo
   */
  public async getCompanies(query: QueryOfCompanies): Promise<void> {
    this.cleanTableState(true);

    if (query.type === CompanyQueryType.CNAE) {
      this.columns = columnsCompanyByCnae;
      this.requestData = await this.companiesService.getCompaniesByCnae(query, 0, 50, false);
    }

    if (query.type === CompanyQueryType.COMPANY_NAME) {
      this.columns = columnsCompanyByCompanyName;
      this.requestData = await this.companiesService.getCompaniesByName(query, 0, 50, false);
    }

    this.resetTableScroll();
    this.renderTableData();

    this.cacheQuery = query;
  }

  /**
   * Deve limpar limpar o estado do componente
   */
  public cleanTableState(notCleanRequestData?: boolean): void {
    if (!notCleanRequestData) this.requestData = null;

    this.paginationPage = 1;
    this.showCompanyDetail = false;
    this.companyDetailData = null;

    this.renderTableData();
  }

  /**
   * Callback disparada pelo componente ms-pagination quando a página é alterada.
   * @param event um evento com o número da página atual
   */
  protected async onChangePagination(event: Event): Promise<void> {
    const data = (event as CustomEvent).detail;
    const offset = this.requestData ? this.requestData.meta.limit * (data - 1) : 0;

    this.tablePaginationUpdateLoading = true;

    if (this.cacheQuery.type === CompanyQueryType.CNAE) {
      this.requestData = await this.companiesService.getCompaniesByCnae(this.cacheQuery, offset, 50, true);
    }

    if (this.cacheQuery.type === CompanyQueryType.COMPANY_NAME) {
      this.requestData = await this.companiesService.getCompaniesByName(this.cacheQuery, offset, 50, true);
    }

    this.renderTableData();
    this.resetTableScroll();

    this.paginationPage = data;
    this.tablePaginationUpdateLoading = false;
  }

  /**
   * Callback do componente ms-table ao realizar ação de redirecionamento para detalhes da empresa
   * @param event dados do evento
   */
  protected async onRedirectDetail(event: Event): Promise<void> {
    this.companyRedirectLoadingById = (event as CustomEvent).detail;
    this.companyDetailData = this.requestData?.data.find((company) => company.id === this.companyRedirectLoadingById) as Company;
    this.companyDetailData.sector = this.companyDetailData.cnaes ? this.companyDetailData.cnaes[0]?.description : '';

    this.renderTableData();

    this.changeDetectorRef.detectChanges();
    await this.companyDetailComponent.getContacts();

    this.showCompanyDetail = true;
    this.companyRedirectLoadingById = null;

    this.renderTableData();
  }

  /**
   *  Callback do componente app-company-detail ao realizar ação de redirecionamento para lista de empresas
   * @param event dados do evento
   */
  protected async onRedirectList(): Promise<void> {
    this.showCompanyDetail = false;
    this.companyDetailData = null;
  }

  /**
   * Deve fazer o map dos contatos para serem renderizados na tabela
   */
  private renderTableData(): void {
    if (this.requestData) {
      this.data = this.requestData?.data.map((company) => {
        return {
          key: company.id,
          empresa: company.name,
          localidade: `${company.address.city} - ${company.address.state}`,
          'contatos disponíveis': company.contactsCount ? company.contactsCount : { type: 'link', content: 'Pesquisar no LinkedIn', href: `https://linkedin.com/search/results/people?keywords=${formatCompanyNameToLinkedIn(company.name)}`, target: '_blank' },
          disabled: !company.contactsCount,
          loading: this.companyRedirectLoadingById === company.id,
        };
      });
    }
  }

  /**
   * Deve definir a posição do scroll da tabela
   */
  private resetTableScroll(): void {
    if (this.table) {
      this.table.el.scrollTop = 0;
    }
  }
}
