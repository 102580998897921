import { CanActivateFn, Router } from '@angular/router';
import { getAccessToken } from './utils/cookie/cookie-auth';
import { inject } from '@angular/core';

const tryGetAccessTokenWithInterval = async (): Promise<string | null> => {
  let token = null;

  for (let i = 0; i < 4; i++) {
    token = getAccessToken();

    await new Promise((resolve) => setTimeout(resolve, 250));

    if (token !== null) {
      break;
    }
  }

  return token;
};

export const authGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);

  const token = await tryGetAccessTokenWithInterval();

  if (state.url === '/capture' && token == null) {
    router.navigateByUrl('/status/unauthorized');

    return false;
  }

  return true;
};
