import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TablePeopleComponent } from './partials/table-people/table-people.component';
import { FilterByCnae, FilterByCnaeAndRole, FilterByCompanyName, FilterByCompanyNameAndRole, FilterByRole } from 'src/app/shared/types/components';
import { CompanyQueryType, ComponentView, ContactQueryType } from 'src/app/shared/enums/components';
import { TableCnaeAndPeopleComponent } from './partials/table-cnae-and-people/table-cnae-and-people.component';
import { TableCnaeAndCompanyComponent } from './partials/table-cnae-and-company/table-cnae-and-company.component';
import { TableCompanyComponent } from './partials/table-company/table-company.component';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss'],
})
export class CaptureComponent {
  @ViewChild(TablePeopleComponent, { static: false }) tablePeopleComponent!: TablePeopleComponent;
  @ViewChild(TableCompanyComponent, { static: false }) tableCompanyComponent!: TableCompanyComponent;
  @ViewChild(TableCnaeAndCompanyComponent, { static: false }) tableCnaeAndCompanyComponent!: TableCnaeAndCompanyComponent;
  @ViewChild(TableCnaeAndPeopleComponent, { static: false }) tableCnaeAndPeopleComponent!: TableCnaeAndPeopleComponent;

  protected filterLoading = false;
  protected currentComponentViewEnum = ComponentView;
  protected currentComponentView: ComponentView = ComponentView.PEOPLE;

  /**
   * Construtor
   * @param changeDetectorRef injeta o serviço para lidar com atualização dos componentes filhos
   */
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Callback e disparado quando é aplicado um novo filtro para buscar por cargo
   */
  protected async onFilterByRole(filterByRole: FilterByRole): Promise<void> {
    this.filterLoading = true;
    this.currentComponentView = ComponentView.PEOPLE;
    this.changeDetectorRef.detectChanges();

    await this.tablePeopleComponent.getContacts({ ...filterByRole, type: ContactQueryType.ROLE });

    this.filterLoading = false;
  }

  /**
   * Callback e disparado quando é aplicado um novo filtro para buscar por empresas
   */
  protected async onFilterByCnae(filterByCnae: FilterByCnae): Promise<void> {
    this.filterLoading = true;
    this.currentComponentView = ComponentView.CNAE_AND_COMPANY;
    this.changeDetectorRef.detectChanges();

    await this.tableCnaeAndCompanyComponent.getCompanyCount(filterByCnae);

    this.filterLoading = false;
  }

  /**
   * Callback e disparado quando é aplicado um novo filtro para buscar por nome de empresa
   */
  protected async onFilterByCompanyName(filterByCompanyName: FilterByCompanyName): Promise<void> {
    this.filterLoading = true;
    this.currentComponentView = ComponentView.COMPANY;
    this.changeDetectorRef.detectChanges();

    await this.tableCompanyComponent.getCompanies({ ...filterByCompanyName, type: CompanyQueryType.COMPANY_NAME });

    this.filterLoading = false;
  }

  /**
   * Callback e disparado quando é aplicado um novo filtro para buscar por nome de empresa e cargo
   */
  protected async onFilterByCompanyNameAndRole(filterByCompanyNameAndRole: FilterByCompanyNameAndRole): Promise<void> {
    this.filterLoading = true;
    this.currentComponentView = ComponentView.PEOPLE;
    this.changeDetectorRef.detectChanges();

    await this.tablePeopleComponent.getContacts({ ...filterByCompanyNameAndRole, type: ContactQueryType.NAME_AND_ROLE });

    this.filterLoading = false;
  }

  /**
   * Callback e disparado quando é aplicado um novo filtro para buscar por empresas e pessoas
   */
  protected async onFilterByCnaeAndRole(filterByCnaeAndRole: FilterByCnaeAndRole): Promise<void> {
    this.filterLoading = true;
    this.currentComponentView = ComponentView.CNAE_AND_PEOPLE;
    this.changeDetectorRef.detectChanges();

    await this.tableCnaeAndPeopleComponent.getContactsCount(filterByCnaeAndRole);

    this.filterLoading = false;
  }

  /**
   * Callback e disparado quando o filtro deve ser limpo
   */
  protected onFilterClean(): void {
    this.tablePeopleComponent?.cleanTableState();
    this.tableCompanyComponent?.cleanTableState();
    this.tableCnaeAndCompanyComponent?.cleanTableState();
    this.tableCnaeAndPeopleComponent?.cleanTableState();
  }
}
