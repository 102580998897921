<div class="sidebar-container">
  <form class="form-container" [formGroup]="form" (submit)="onFilter()">
    <ms-title semantic="h5" class="ms-padding-16">Pesquisa</ms-title>
    <ms-divider></ms-divider>

    <div class="imputs">
      <div>
        <div class="ms-padding-top-16 ms-padding-horizontal-16">
          <ms-subtitle class="ms-margin-bottom-4" semantic="span">Empresa</ms-subtitle>
          <ms-caption class="ms-margin-bottom-16 caption">
            <span class="description">Busque pelo nome fantasia.</span>
          </ms-caption>
          <div class="ms-margin-bottom-8">
            <ms-input placeholder="Busque pelo nome da empresa" size="medium" formControlName="companyName" [minlength]="4" errorText="digite pelo menos 4 caracteres" (eInput)="onCompanyNameInput()"></ms-input>
          </div>
        </div>

        <ms-divider></ms-divider>

        <div class="ms-padding-16">
          <ms-subtitle class="ms-margin-bottom-4" semantic="span">Setor </ms-subtitle>
          <ms-caption class="ms-margin-bottom-16 caption">
            <span class="description">Conheça os CNAEs na nossa <ms-link size="12" role="link" target="_blank" [href]="sector" color="secondary" weight="400">base de conhecimento</ms-link>.</span>
          </ms-caption>
          <ms-button-toggle class="ms-margin-bottom-12" content='[{ "name": "Buscar por Setor", "key": "1" }, { "name": "Buscar por CNAE", "key": "2" }]' formControlName="toggle" (eChange)="onButtonToggleChange()"></ms-button-toggle>
          <div class="ms-margin-bottom-8" [hidden]="form.controls.toggle.value !== '1'">
            <ms-select placeholder="– Selecione um setor –" placeholder-search="Busca por setor..." size="medium" [content]="listOfSectors" formControlName="cnaes" (eInput)="onSelectCnaesInput()" [tagWidth]="60" [tagSkip]="3" [minCharacterSearch]="1" [limit]="5" show-tag-tooltip show-list-item-tooltip multiple></ms-select>
            <ms-button [disabled]="form.controls.cnaes.disabled" class="clean" fill="outline" size="small" color="secondary" size="small" (eClick)="onCleanCnaes()">Limpar seleção</ms-button>
          </div>
          <div class="ms-margin-bottom-8" [hidden]="form.controls.toggle.value !== '2'">
            <ms-select placeholder="– Selecione um cnae –" placeholder-search="Busca por cnae..." size="medium" [content]="listOfCanes" formControlName="cnaes" (eInput)="onSelectCnaesInput()" [tagWidth]="95" [tagSkip]="2" [minCharacterSearch]="1" [limit]="5" show-tag-tooltip show-list-item-tooltip multiple></ms-select>
            <ms-button [disabled]="form.controls.cnaes.disabled" class="clean" fill="outline" size="small" color="secondary" size="small" (eClick)="onCleanCnaes()">Limpar seleção</ms-button>
          </div>
        </div>

        <ms-divider></ms-divider>

        <div class="ms-padding-16">
          <ms-subtitle class="ms-margin-bottom-4" semantic="span">Cargo</ms-subtitle>
          <ms-caption class="ms-margin-bottom-16 caption">
            <span class="description">Busque por um ou mais cargos</span>
          </ms-caption>
          <div class="ms-margin-bottom-8">
            <ms-search-multiple placeholder="Busque por cargo" size="medium" formControlName="roles" limit="10" [minValueLength]="2"></ms-search-multiple>
            <ms-button class="clean" fill="outline" size="small" color="secondary" size="small" (eClick)="onCleanRoles()">Limpar seleção</ms-button>
          </div>
        </div>
      </div>

      <ms-divider></ms-divider>

      <ms-expansion-card open="true">
        <span slot="title">Filtros</span>
        <div class="expansion-card-content" slot="content">
          <div class="ms-padding-16">
            <ms-subtitle class="ms-margin-bottom-4 ms-margin-bottom-16" semantic="span">Localidade</ms-subtitle>
            <div class="ms-margin-bottom-8">
              <ms-select show-tag-tooltip placeholder="– Selecione um estado –" placeholder-search="Busca por estado..." size="medium" [content]="listOfStates" formControlName="states" (eInput)="onSelectStatesInput()" [minCharacterSearch]="1" [tagWidth]="60" [tagSkip]="3" multiple></ms-select>
              <ms-button [disabled]="form.controls.states.disabled" class="clean" fill="outline" size="small" color="secondary" size="small" (eClick)="onCleanStates()">Limpar seleção</ms-button>
            </div>
            <div class="ms-margin-bottom-8">
              <ms-select show-tag-tooltip placeholder="– Selecione a cidade –" placeholder-search="Busca por cidade..." size="medium" [content]="listOfCities" formControlName="cities" [minCharacterSearch]="1" [tagWidth]="90" [tagSkip]="2" multiple></ms-select>
              <ms-button [disabled]="form.controls.cities.disabled" class="clean" fill="outline" size="small" color="secondary" size="small" (eClick)="onCleanCities()">Limpar seleção</ms-button>
            </div>
          </div>

          <ms-divider></ms-divider>

          <div class="ms-padding-16" formGroupName="port">
            <ms-subtitle class="ms-margin-bottom-4" semantic="span">Tamanho da Empresa</ms-subtitle>
            <ms-caption class="ms-margin-bottom-16 caption">
              <span class="description">Filtre pela quantidade de funcionários da empresa.</span>
            </ms-caption>
            <div class="port">
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_1_10"> 1-10 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_11_50"> 11-50 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_51_200"> 51-200 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_201_500"> 201-500 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_501_1000"> 501-1.000 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_1001_5000"> 1.001-5.000 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_5001_10000"> 5.001-10.000 </ms-checkbox>
              </div>
              <div class="ms-margin-bottom-8">
                <ms-checkbox color="primary" formControlName="range_10000_plus"> + 10.000 </ms-checkbox>
              </div>
            </div>
          </div>

          <ms-divider></ms-divider>

          <div class="ms-padding-16" formGroupName="confiability">
            <ms-subtitle class="ms-margin-bottom-4" semantic="span">Confiabilidade</ms-subtitle>
            <ms-caption class="ms-margin-bottom-16 caption">
              <span class="description">Saiba mais na nossa <ms-link size="12" role="link" target="_blank" [href]="reliability" color="secondary" weight="400">base de conhecimento</ms-link>.</span>
            </ms-caption>
            <div class="ms-margin-bottom-8">
              <ms-checkbox color="primary" formControlName="high"> Alta </ms-checkbox>
              <ms-icon id="icon-high-tooltip" class="ms-margin-start-8" name="InfoCircleOutlined" size="14"></ms-icon>
              <ms-tooltip trigger="icon-high-tooltip" position="top-right">O contato foi validado na base do Prospect.</ms-tooltip>
            </div>
            <div class="ms-margin-bottom-8">
              <ms-checkbox color="primary" formControlName="medium"> Média </ms-checkbox>
              <ms-icon id="icon-medium-tooltip" class="ms-margin-start-8" name="InfoCircleOutlined" size="14"></ms-icon>
              <ms-tooltip trigger="icon-medium-tooltip" position="top-right">O contato não foi validado pelo Prospect mas tem grandes chances de estar correto.</ms-tooltip>
            </div>
            <div class="ms-margin-bottom-8">
              <ms-checkbox color="primary" formControlName="low"> Baixa </ms-checkbox>
              <ms-icon id="icon-low-tooltip" class="ms-margin-start-8" name="InfoCircleOutlined" size="14"></ms-icon>
              <ms-tooltip trigger="icon-low-tooltip" position="top-right">O Prospect não encontrou o e-mail padrão da empresa na base de dados.</ms-tooltip>
            </div>
            <div class="ms-margin-bottom-8">
              <ms-checkbox color="primary" formControlName="none"> Sem </ms-checkbox>
              <ms-icon id="icon-none-tooltip" class="ms-margin-start-8" name="InfoCircleOutlined" size="14"></ms-icon>
              <ms-tooltip trigger="icon-none-tooltip" position="top-right">O contato foi inserido manualmente e ainda não foi verificado.</ms-tooltip>
            </div>
          </div>
        </div>
      </ms-expansion-card>

      <ms-divider></ms-divider>
    </div>

    <ms-divider></ms-divider>

    <div class="action">
      <ms-button size="medium" fill="text" (eClick)="onClean()">Limpar Tudo</ms-button>
      <ms-button size="medium" type="submit" [disabled]="form.controls.cnaes.invalid && form.controls.roles.invalid && form.controls.companyName.invalid" [loading]="loading">Filtrar</ms-button>
    </div>
  </form>
</div>
