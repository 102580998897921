import { DateTime } from 'luxon';
import { AuthType } from 'src/app/shared/types/auth';
import { environment } from 'src/environments/environment';

/**
 * Deve consultar os cookies e buscar pelo cookieName
 * @returns o conteúdo do cookie
 */
function getCookie(cookieName: string): string | null {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

/**
 * Deve salvar um novo cookie com os novos tokens
 * @param data um objeto que deve conter o accessToken e o refreshToken atualizados
 */
export function setCookie(data: AuthType): void {
  const expires = DateTime.now().plus({ hours: 1 }).toJSDate().toUTCString();

  if (environment.domain.indexOf('localhost') !== -1) {
    document.cookie = `capturas_localhost_auth_token=${data.accessToken}|~|${data.refreshToken};expires=${expires};domain=localhost;path=/`;
  }

  if (environment.domain.indexOf('test.ramper.com.br') !== -1) {
    document.cookie = `capturas_staging_auth_token=${data.accessToken}|~|${data.refreshToken};expires=${expires};domain=ramper.com.br;path=/`;
  }

  if (environment.domain.indexOf('go.ramper.com.br') !== -1) {
    document.cookie = `capturas_auth_token=${data.accessToken}|~|${data.refreshToken};expires=${expires};domain=ramper.com.br;path=/`;
  }
}

/**
 * Deve consultar o cookie e obter o accessToken
 * @returns string contento o accessToken
 */
export function getAccessToken(): string | null {
  if (environment.domain.indexOf('localhost') !== -1) {
    return getCookie('capturas_localhost_auth_token')?.split('|~|')[0] || null;
  }

  if (environment.domain.indexOf('test.ramper.com.br') !== -1) {
    return getCookie('capturas_staging_auth_token')?.split('|~|')[0] || null;
  }

  if (environment.domain.indexOf('go.ramper.com.br') !== -1) {
    return getCookie('capturas_auth_token')?.split('|~|')[0] || null;
  }

  return null;
}

/**
 * Deve consultar o cookie e obter o refreshToken
 * @returns string contento o refreshToken
 */
export function getRefreshToken(): string | null {
  if (environment.domain.indexOf('localhost') !== -1) {
    return getCookie('capturas_localhost_auth_token')?.split('|~|')[1] || null;
  }

  if (environment.domain.indexOf('test.ramper.com.br') !== -1) {
    return getCookie('capturas_staging_auth_token')?.split('|~|')[1] || null;
  }

  if (environment.domain.indexOf('go.ramper.com.br') !== -1) {
    return getCookie('capturas_auth_token')?.split('|~|')[1] || null;
  }

  return null;
}
