import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthType } from '../shared/types/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Consome a api para obter gerar um novo token de acesso
   * @param refreshToken token para gerar um novo accessToken
   * @returns deve retornar um objeto { accessToken: string; refreshToken: string }
   */
  refreshToken(refreshToken: string | null): Promise<AuthType> {
    return firstValueFrom(this.http.post<AuthType>(`${environment.api}/api/v1/auth/refresh`, { refreshToken }));
  }
}
