import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-present-problems',
  templateUrl: './present-problems.component.html',
  styleUrls: ['./present-problems.component.scss'],
})
export class PresentProblemsComponent implements OnInit {
  protected currentRoute!: string;

  /**
   * Constructor
   * @param route injeta a service para gerenciar rotas
   */
  constructor(private route: ActivatedRoute) {}

  /**
   * Callback para o ciclo de vida do componente
   * É invocado apenas uma vez quando a diretiva é instanciada.
   */
  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    this.currentRoute = snapshot.url.map((segment) => segment.path).join('/');
  }
}
