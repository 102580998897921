import { Component } from '@angular/core';
import { IframePostMessageService } from './services/iframe-post-message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Construtor
   * @param postMessageService injeta o serviço para lidar com a comunicação com cliente
   */
  constructor(private iframePostMessageService: IframePostMessageService) {
    this.iframePostMessageService.onCaptureMode();
    this.iframePostMessageService.onCacheUniqueKey();
    this.iframePostMessageService.onSyncContactCaptured();
  }
}
