import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalAction } from 'src/app/shared/enums/components';

@Component({
  selector: 'app-modal-confirm-contact-capture',
  templateUrl: './modal-confirm-contact-capture.component.html',
  styleUrls: ['./modal-confirm-contact-capture.component.scss'],
})
export class ModalConfirmContactCaptureComponent {
  protected numberOfContacts = 0;
  protected open = false;

  @Output() confirme = new EventEmitter<void>();
  @Output() action = new EventEmitter<ModalAction>();

  /**
   * Deve disparar um evento de confirmação e fechar a modal
   */
  public show(numberOfContacts: number): void {
    this.open = true;
    this.numberOfContacts = numberOfContacts;
  }

  /**
   * Deve disparar um evento de confirmação e fechar a modal
   */
  protected onConfirmAction(): void {
    this.open = false;
    this.action.emit(ModalAction.CONFIRM);
  }

  /**
   * Deve disparar um evento de cancelamento e fechar a modal
   */
  protected onCancelAction(): void {
    this.open = false;
    this.action.emit(ModalAction.CANCEL);
  }
}
