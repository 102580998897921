import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-state-message',
  templateUrl: './empty-state-message.component.html',
  styleUrls: ['./empty-state-message.component.scss'],
})
export class EmptyStateMessageComponent {
  protected readonly link: string = 'https://ramperprospect.movidesk.com/kb/pt-br/article/459284/como-usar-o-descobrir-contatos';
}
