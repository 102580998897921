import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInjectInterceptorService } from './middleware/jwt-inject-interceptor';
import { CaptureModule } from './pages/capture/capture.module';
import { AppRoutingModule } from './app-routing.module';
import { PresentProblemsModule } from './pages/present-problems/present-problems.module';
import { IframePostMessageService } from './services/iframe-post-message.service';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'RamperCapturas',
  version: 3,
  objectStoresMeta: [
    {
      store: 'publish-contacts-cache',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'created_at', keypath: 'created_at', options: { unique: true } }],
    },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, NgxIndexedDBModule.forRoot(dbConfig), CaptureModule, PresentProblemsModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInjectInterceptorService, multi: true }, IframePostMessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
