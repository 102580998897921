import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'capture',
    pathMatch: 'full',
  },
  {
    path: 'capture',
    loadChildren: () => import('./pages/capture/capture.module').then((m) => m.CaptureModule),
    canActivate: [authGuard],
  },
  {
    path: 'status',
    loadChildren: () => import('./pages/present-problems/present-problems.module').then((m) => m.PresentProblemsModule),
  },
];
