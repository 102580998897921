import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PresentProblemsComponent } from './present-problems.component';
import { SessionExpiredMessageComponent } from '../../components/session-expired-message/session-expired-message.component';
import { AccessDeniedMessageComponent } from '../../components/access-denied-message/access-denied-message.component';
import { InternalServerErrorMessageComponent } from '../../components/internal-server-error-message/internal-server-error-message.component';
import { CommonModule } from '@angular/common';
import { NotFoundMessageComponent } from '../../components/not-found-message/not-found-message.component';
import { MonsterWebKitModule } from 'src/app/monster-web-kit.module';

const routes: Routes = [
  {
    path: 'session-expired',
    component: PresentProblemsComponent,
  },
  {
    path: 'internal-server-error',
    component: PresentProblemsComponent,
  },
  {
    path: 'not-found',
    component: PresentProblemsComponent,
  },
  {
    path: 'unauthorized',
    component: PresentProblemsComponent,
  },
];

@NgModule({
  declarations: [PresentProblemsComponent, SessionExpiredMessageComponent, AccessDeniedMessageComponent, InternalServerErrorMessageComponent, NotFoundMessageComponent],
  imports: [CommonModule, RouterModule.forChild(routes), MonsterWebKitModule],
})
export class PresentProblemsModule {}
