import { DisabledReason } from 'src/app/shared/enums/contacts';
import { Contact } from 'src/app/shared/types/contacts';
import { TextIconStructure } from '@techramper/monster-web-kit-core/dist/types/components/ms-table/ms-table-interface';

/**
 * Deve criar uma estrutura que represente o componente text-icon
 */
export function createContactDisabledComponent(contact: Contact): TextIconStructure | undefined {
  if (contact.disabledReason?.type === DisabledReason.DANGER) {
    return {
      type: 'text-icon',
      content: contact.disabledReason?.title,
      prefix: { name: 'MinusCircleOutlined', color: 'danger' },
      suffix: { name: 'InfoCircleOutlined', color: 'current-color', tooltip: contact.disabledReason?.description },
    } as unknown as TextIconStructure;
  }

  if (contact.disabledReason?.type === DisabledReason.INFO) {
    return {
      type: 'text-icon',
      content: contact.disabledReason?.title,
      prefix: { name: 'FlagOutlined', color: 'current-color' },
      suffix: { name: 'InfoCircleOutlined', color: 'current-color', tooltip: contact.disabledReason?.description },
    } as unknown as TextIconStructure;
  }

  if (contact.disabledReason?.type === DisabledReason.TIMER) {
    return {
      type: 'text-icon',
      content: contact.disabledReason?.title,
      prefix: { name: 'ClockCircleOutlined', color: 'warning' },
      suffix: { name: 'InfoCircleOutlined', color: 'current-color', tooltip: contact.disabledReason?.description },
    } as unknown as TextIconStructure;
  }

  return;
}
