import { Directive, HostBinding, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Directive({
  selector: 'ms-checkbox[]',

  providers: [
    NgModel,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppMsCheckboxDirective),
      multi: true,
    },
  ],
})
export class AppMsCheckboxDirective implements ControlValueAccessor {
  @HostBinding('checked') checked = false;
  @HostBinding('disabled') disabled = false;

  /**/
  private onChange = (value: any): void => {};

  /**/
  private onTouched = (): void => {};

  /**
   * Callback para atualizar o valor no componente
   * @param value valor definido no formControl
   */
  writeValue(value: any): void {
    this.checked = value;
  }

  /**
   *  Callback para desabilitar o componente
   * @param disabled
   */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  /**
   * Callback disparada quando acontecer alguma alteração no valor do formControl
   * @param fn retorno de chamada para registrar
   */
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Callback disparada quando acontecer alguma alteração no formControl
   * @param fn retorno de chamada para registrar
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Evento que fica escutando os eventos disparados pelo componente
   * @param value valor definido pelo componente
   */
  @HostListener('eChange', ['$event.detail'])
  _handleInputEvent(value: any): void {
    this.checked = value;

    this.onChange(value);
    this.onTouched();
  }
}
