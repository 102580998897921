import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { getPropsReliability } from 'src/app/utils/email/reliability';
import { PublishContact, RequestData } from 'src/app/shared/types/contacts';
import { QueryOfContacts, TablePeopleOthers } from 'src/app/shared/types/components';
import { ContactCaptureMode, UserAction } from 'src/app/shared/enums/post-message';
import { contactProcessing } from 'src/app/shared/constants/contacts';
import { createContactReliabilityComponent } from 'src/app/utils/contact/table-component-tag';
import { createContactDisabledComponent } from 'src/app/utils/contact/table-component-text-icon';
import { ModalConfirmContactCaptureComponent } from 'src/app/components/modal-confirm-contact-capture/modal-confirm-contact-capture.component';
import { ModalAction, ContactQueryType } from 'src/app/shared/enums/components';
import { columnsContactByCompanyId, columnsContactByCompanyAndRole, columnsContactByRole, columnsContactByCompanyName } from 'src/app/shared/constants/components';
import { IframePostMessageService } from 'src/app/services/iframe-post-message.service';
import { ObservableSourceType } from 'src/app/shared/enums/service';
import { Subscription } from 'rxjs';
import { createContactTextTooltipComponent } from 'src/app/utils/contact/table-component-text-tooltip';

@Component({
  selector: 'app-table-people',
  templateUrl: './table-people.component.html',
  styleUrls: ['./table-people.component.scss'],
})
export class TablePeopleComponent implements OnInit, OnDestroy {
  @Input({ required: true }) tablePeopleId!: string;

  @ViewChild(ModalConfirmContactCaptureComponent) private modalConfirmContactCaptureComponent!: ModalConfirmContactCaptureComponent;
  @ViewChild('tablePeopleRef') private table!: { el: HTMLMsTableElement };

  protected columns: Array<unknown> = [];
  protected data: Array<Record<string, unknown>> = [];
  protected listOfContactsSelected: Array<{ page: number; id: string }> = [];
  protected selectAllController = 'unchecked';
  protected paginationPage = 1;
  protected requestData!: RequestData | null;
  protected tablePaginationUpdateLoading!: boolean;
  protected saveContactsSelectedLoading!: boolean;
  protected queryTypeEnum = ContactQueryType;
  protected cacheQuery!: QueryOfContacts;
  protected cacheOthers!: TablePeopleOthers | undefined;

  private contactsSubject!: Subscription;

  /**
   * Construtor
   * @param contactsService injeta o serviço para lidar com contatos
   * @param iframePostMessageService injeta o serviço para lidar com a comunicação com cliente
   */
  constructor(
    private readonly contactsService: ContactsService,
    private readonly iframePostMessageService: IframePostMessageService,
  ) {}

  /**
   * Callback para o ciclo de vida do componente
   * É invocado apenas uma vez quando a diretiva é instanciada.
   */
  ngOnInit(): void {
    this.contactsService.execute(this.tablePeopleId);
    this.onSyncContacts();
  }

  /**
   * Callback para o ciclo de vida do componente
   * É invocado apenas uma vez quando a diretiva é destruida.
   */
  ngOnDestroy(): void {
    this.contactsSubject.unsubscribe();
  }

  /**
   * Deve adicionar um listener para receber a lista de contatos
   */
  private onSyncContacts(): void {
    const observable = this.contactsService.observe(this.tablePeopleId);

    this.contactsSubject = observable.subscribe((response) => {
      if (response.requestData) {
        this.requestData = response.requestData;
        this.renderTableData();

        if (response.observableSourceType === ObservableSourceType.http) {
          this.cleanTableState(true);
          this.resetTableScroll();
        }
      }
    });
  }

  /**
   * Callback e disparada quando é aplicado um novo filtro para buscar por cargo
   */
  public async getContacts(query: QueryOfContacts, others?: TablePeopleOthers): Promise<void> {
    if (query.type === ContactQueryType.NAME_AND_ROLE) {
      this.columns = columnsContactByCompanyName;
      await this.contactsService.getContactsByCompanyNameAndRole(this.tablePeopleId, query, 0, 50, false);
    }

    if (query.type === ContactQueryType.ROLE) {
      this.columns = columnsContactByRole;
      await this.contactsService.getContactsByRoles(this.tablePeopleId, query, 0, 50, false);
    }

    if (query.type === ContactQueryType.COMPANY_AND_ROLE) {
      this.columns = columnsContactByCompanyId;
      await this.contactsService.getContactsByCompanyId(this.tablePeopleId, query, 0, 50, false);
    }

    if (query.type === ContactQueryType.CNAE_AND_ROLE) {
      this.columns = columnsContactByCompanyAndRole;
      await this.contactsService.getContactsByCnaeAndRole(this.tablePeopleId, query, 0, 50, false);
    }

    this.cacheQuery = query;
    this.cacheOthers = others;
  }

  /**
   * Deve limpar limpar o estado do componente
   */
  public cleanTableState(notCleanRequestData?: boolean): void {
    if (!notCleanRequestData) this.requestData = null;

    this.listOfContactsSelected = [];
    this.selectAllController = 'unchecked';
    this.paginationPage = 1;

    this.renderTableData();
  }

  /**
   * Deve fazer o map dos contatos para serem renderizados na tabela
   */
  private renderTableData(): void {
    if (this.requestData) {
      this.data = this.requestData?.data.map((contact) => {
        const reliability = getPropsReliability(contact.email.reliability);

        return {
          key: contact.id,
          nome: `${contact.name} ${contact.lastName}`,
          empresa: contact?.company?.name || '',
          cargo: contact.role ? (contact.role == '.' || contact.role == '-' ? '--' : createContactTextTooltipComponent(contact.role, contact.role)) : '--',
          localidade: contact?.company?.address ? `${contact.company.address.city} - ${contact.company.address.state}` : '',
          confiabilidade: createContactReliabilityComponent(reliability),
          'e-mail': contact.disabled ? createContactDisabledComponent(contact) : contact.email.email,
          disabled: contact.disabled,
          checked: this.listOfContactsSelected.some((item) => item.id === contact.id),
        };
      });

      this.handleSelectAllController();
    }
  }

  /**
   * Deve definir a posição do scroll da tabela
   */
  private resetTableScroll(): void {
    if (this.table) {
      this.table.el.scrollTop = 0;
    }
  }

  /**
   * Deve analisar como deve ser apresentado o estado do selecionar todos.
   */
  private handleSelectAllController(): void {
    const numberContactsSelectedPerPage = this.listOfContactsSelected.filter((item) => item.page === this.paginationPage).length;

    if (!numberContactsSelectedPerPage && this.listOfContactsSelected.length >= 1) {
      this.selectAllController = 'indeterminate';
      return;
    }

    if (!numberContactsSelectedPerPage) {
      this.selectAllController = 'unchecked';
      return;
    }

    if (this.requestData && this.requestData.meta.pageCount == 1 && numberContactsSelectedPerPage == this.requestData.meta.itemCount - this.requestData.data.filter((item) => item.disabled).length) {
      this.selectAllController = 'checked';
      return;
    }

    if (this.requestData && numberContactsSelectedPerPage && numberContactsSelectedPerPage < this.requestData.meta.limit - this.requestData.data.filter((item) => item.disabled).length) {
      this.selectAllController = 'indeterminate';
      return;
    }

    if (this.requestData && numberContactsSelectedPerPage == this.requestData.meta.limit - this.requestData.data.filter((item) => item.disabled).length) {
      this.selectAllController = 'checked';
      return;
    }
  }

  /**
   * Callback disparada pelo componente ms-pagination quando a página é alterada.
   * @param event um evento com o número da página atual
   */
  protected async onChangePagination(event: Event): Promise<void> {
    const data = (event as CustomEvent).detail;
    const offset = this.requestData ? this.requestData.meta.limit * (data - 1) : 0;

    this.tablePaginationUpdateLoading = true;

    if (this.cacheQuery.type === ContactQueryType.NAME_AND_ROLE) {
      await this.contactsService.getContactsByCompanyNameAndRole(this.tablePeopleId, this.cacheQuery, offset, 50, true);
    }

    if (this.cacheQuery.type === ContactQueryType.ROLE) {
      await this.contactsService.getContactsByRoles(this.tablePeopleId, this.cacheQuery, offset, 50, true);
    }

    if (this.cacheQuery.type === ContactQueryType.COMPANY_AND_ROLE) {
      await this.contactsService.getContactsByCompanyId(this.tablePeopleId, this.cacheQuery, offset, 50, true);
    }

    if (this.cacheQuery.type === ContactQueryType.CNAE_AND_ROLE) {
      await this.contactsService.getContactsByCnaeAndRole(this.tablePeopleId, this.cacheQuery, offset, 50, true);
    }

    this.paginationPage = data;
    this.tablePaginationUpdateLoading = false;
  }

  /**
   * Callback disparada pelo componente ms-table quando o selecionar item tem modificações.
   * @param event um evento com o número da página atual
   */
  protected onChangeOneCheckbox(event: Event): void {
    const data = (event as CustomEvent).detail;

    if (data.checked) {
      this.listOfContactsSelected.push({ page: this.paginationPage, id: data.key });
    }

    if (!data.checked) {
      const index = this.listOfContactsSelected.findIndex((item) => item.id === data.key);
      if (index !== -1) this.listOfContactsSelected.splice(index, 1);
    }

    this.renderTableData();
  }

  /**
   * Callback disparada pelo componente ms-table quando o selecionar todos tem modificações.
   * @param event um evento com o número da página atual
   */
  protected onChangeAllCheckbox(event: Event): void {
    const data = (event as CustomEvent).detail;

    if (data.checked) {
      data.keys.forEach((key: string) => {
        if (!this.listOfContactsSelected.some((item) => item.id === key)) {
          this.listOfContactsSelected.push({ page: this.paginationPage, id: key });
        }
      });
    } else {
      data.keys.forEach((key: string) => {
        const index = this.listOfContactsSelected.findIndex((item) => item.id === key);
        if (index !== -1) this.listOfContactsSelected.splice(index, 1);
      });
    }

    this.renderTableData();
  }

  /**
   * Deve verificar se pode capturar os contatos e chamar o método de publicação
   */
  protected async onCanContactsCapture(): Promise<void> {
    this.saveContactsSelectedLoading = true;

    const contacts = this.listOfContactsSelected.map((item) => {
      return { contactId: item.id, companyId: '' };
    });

    try {
      const result = await this.iframePostMessageService.canContactsCapture(contacts.length);
      const data = { contacts: contacts, extras: result.extras || {} } as PublishContact;

      if (result.contactCaptureMode == ContactCaptureMode.standard) {
        this.modalConfirmContactCaptureComponent.show(contacts.length);

        const action$ = this.modalConfirmContactCaptureComponent.action.subscribe(async (action: ModalAction) => {
          action === ModalAction.CONFIRM ? await this.publishContacts(data) : (this.saveContactsSelectedLoading = false);
          action$.unsubscribe();
        });
      }

      if (result.contactCaptureMode == ContactCaptureMode.specific) {
        await this.publishContacts(data);
      }
    } catch (error) {
      this.saveContactsSelectedLoading = false;
    }
  }

  /**
   * Deve consumir o serviço para publicar contatos e atualizar a tabela;
   */
  private async publishContacts(data: PublishContact): Promise<void> {
    await this.contactsService.publishContacts(data);

    this.iframePostMessageService.userActionLogger(UserAction.CAPTURE_CONTACT, this.cacheQuery);

    if (this.requestData?.data.length) {
      this.requestData.data = this.requestData?.data.map((contact) => {
        if (data.contacts.find((item) => item.contactId === contact.id)) {
          return { ...contact, disabled: true, disabledReason: contactProcessing };
        }

        return contact;
      });

      this.listOfContactsSelected = [];

      this.renderTableData();

      this.saveContactsSelectedLoading = false;
    }
  }
}
