<ms-modal [open]="open" max-width="410px" (eClose)="onCancelAction()">
  <div slot="content">
    <div style="display: flex; gap: 8px; align-items: center">
      <ms-icon name="ExclamationCircleOutlined" color="secondary" size="24"></ms-icon>
      <ms-title *ngIf="numberOfContacts > 1" semantic="h6">Deseja Salvar {{ numberOfContacts }} Contatos ?</ms-title>
      <ms-title *ngIf="numberOfContacts === 1" semantic="h6">Deseja Salvar {{ numberOfContacts }} Contato ?</ms-title>
    </div>
  </div>
  <div slot="action" style="display: flex; gap: 24px">
    <ms-button size="medium" (click)="onConfirmAction()">Salvar</ms-button>
    <ms-button fill="outline" size="medium" (click)="onCancelAction()">Cancelar</ms-button>
  </div>
</ms-modal>
