<div class="container-detail">
  <div class="ms-margin-horizontal-24 ms-margin-top-24">
    <div class="header">
      <ms-button class="button-redirect-list" color="light" size="full" (click)="redirect.emit()">
        <ms-icon name="ArrowLeftOutlined" size="14"></ms-icon>
      </ms-button>

      <div class="company-logo">
        <img appHideMissing [src]="getImageLink()" alt="Logo da Empresa" />
      </div>

      <div *ngIf="company" class="company-detail">
        <div>
          <div class="label">NOME</div>
          <ms-text>{{ company.name }}</ms-text>
        </div>
        <div>
          <div class="label">PORTE</div>
          <ms-text>{{ company.size ? (company.size | formatRange) + ' funcionários' : '' }}</ms-text>
        </div>
        <div>
          <div class="label">SETOR</div>
          <ms-text>{{ company.sector }}</ms-text>
        </div>
        <div>
          <div class="label">LOCALIDADE</div>
          <ms-text>{{ company.address.city }} - {{ company.address.state }}</ms-text>
        </div>
        <div>
          <div class="label">TELEFONE</div>
          <ms-text>{{ company.phones[0] }}</ms-text>
        </div>
        <div>
          <div class="label">CONTATOS DISPONÍVEIS</div>
          <ms-text>{{ company.contactsCount }}</ms-text>
        </div>
      </div>
    </div>

    <div>
      <ms-divider class="ms-margin-top-8 ms-margin-bottom-12"></ms-divider>
      <ms-input type="search" placeholder="Busque por cargo" size="medium" reactive-input-message-disabled="false" (eInput)="onInputSearch($event)" [loading]="showInputSearchLoading"></ms-input>
    </div>
  </div>

  <div class="table-people">
    <app-table-people *ngIf="company" [tablePeopleId]="company.id"></app-table-people>
  </div>
</div>
